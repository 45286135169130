import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import Catalogue from "../components/Catalogue";


class Home extends Component {
  render() {
    return (
      <div className="Home">
        <Navbar />

        <Catalogue />

      </div>
    )
  }
}

export default Home
