import React from 'react';
import { useParams } from 'react-router-dom';
import Login from "./Login";

function WrappedLogin() {
  const { isReset } = useParams();
  return (
    <Login isReset={isReset}/>
  );
}

export default WrappedLogin;