import { authHeader } from '../helpers';
import { generalConstants } from '../constants';

export const vFactsService = {
    getAll,
};

function getAll(companyName, fromDate, toDate) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    console.log(requestOptions);
    if(companyName){
      return fetch(generalConstants.SERVER_URL + "/api/vFacts_ContentHistories?filter=%7B%22where%22%3A%7B%22CompanyName%22%3A%20%22" + encodeURIComponent(companyName) + "%22%2C%20%22Accessed%22%3A%7B%22between%22%3A%20%5B%22" + encodeURIComponent(fromDate) + "%22%2C%22" + encodeURIComponent(toDate) + "%22%5D%7D%7D%7D", requestOptions).then(handleResponse);
    } else {
      return fetch(generalConstants.SERVER_URL + "/api/vFacts_ContentHistories?filter=%7B%22where%22%3A%7B%22Accessed%22%3A%7B%22between%22%3A%20%5B%22" + encodeURIComponent(fromDate) + "%22%2C%22" + encodeURIComponent(toDate) + "%22%5D%7D%7D%7D", requestOptions).then(handleResponse);
    }
}


function handleResponse(response) {
    if (!response.ok) {
        return Promise.reject(response.statusText);
    }
    return response.json();
}
