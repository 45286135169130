import { passwordreset_logConstants } from '../constants';
import { passwordreset_logService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const passwordreset_logActions = {
    getAll,
    delete: _delete,
    update
};

function getAll() {
    return dispatch => {
        dispatch(request());

        passwordreset_logService.getAll()
            .then(
                passwordreset_logs => dispatch(success(passwordreset_logs)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: passwordreset_logConstants.GETALL_REQUEST } }
    function success(passwordreset_logs) { return { type: passwordreset_logConstants.GETALL_SUCCESS, passwordreset_logs } }
    function failure(error) { return { type: passwordreset_logConstants.GETALL_FAILURE, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request());

        passwordreset_logService.delete(id)
            .then(
                passwordreset_logs => dispatch(success(passwordreset_logs)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: passwordreset_logConstants.DELETE_REQUEST } }
    function success(passwordreset_logs) { return { type: passwordreset_logConstants.DELETE_SUCCESS, passwordreset_logs } }
    function failure(error) { return { type: passwordreset_logConstants.DELETE_FAILURE, error } }
}
function update(passwordreset_log) {
    return dispatch => {
        dispatch(request(passwordreset_log));

        passwordreset_logService.update(passwordreset_log)
            .then(
                user => {
                    //user is response.json when response.ok
                    dispatch(success());

                    dispatch(alertActions.success("If your email address is registered with us you'll receive an email with a new password. \n Remember to check your spam folder \n Emails could take up to 5 minutes to arrive"));
                    //dispatch(alertActions.success('Update successful'));
                },
                error => {
                    //error is response.json when !response.ok
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(passwordreset_log) { return { type: passwordreset_logConstants.UPDATE_REQUEST, passwordreset_log } }
    function success(passwordreset_log) { return { type: passwordreset_logConstants.UPDATE_SUCCESS, passwordreset_log } }
    function failure(error) { return { type: passwordreset_logConstants.UPDATE_FAILURE, error } }
}
