import React, { Component } from 'react';
class LikeButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      likes: props.likes, //Number of likes
    };
  }
  onClick(e, i) {
    //send like here
    alert("component liked")
  }
  render() {
    if(this.props.enabled){
      return (
        <div className="like" onClick={(e) => {return this.onClick(e, this)}}><i className="fa fa-thumbs-up"></i><span className="badge badge-light">{this.state.likes}</span></div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}
  export default LikeButton
