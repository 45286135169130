import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import Jumbotron from '../components/Jumbotron';
import { connect } from 'react-redux';


class Failure extends Component {

    render() {
        return (
            <div className="FAQ">
                <Navbar LoggedIn="true" />
                <Jumbotron
                    title="Failure"
                    subtitle=""
                />
                <div className="container" style={{ "paddingTop": "10px" }}>
                    <div className="row">
                        <h3 className="col-12">Failure</h3>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const { brandings } = state;
    return {
        brandings
    };
}

const connectedFailure = connect(mapStateToProps)(Failure);

export default connectedFailure;
