import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated, children, ...rest }) => {
    const location = useLocation();

    if(!isAuthenticated){
        return <Navigate to="/login" state={{from: location}} />
    }
    return children;
};

export default PrivateRoute;