import React, { Component } from 'react';
class likeBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      likes: props.likes,
      dislikes: props.dislikes
    };
  }
  render() {
    if(this.props.enabled){
      return (
        <div>
          <div className="dislikebar"></div>
          <div className="likebar" style={{width: (this.state.likes / (this.state.dislikes + this.state.likes + 0.000001) * 100).toString() + "%"}}></div>
        </div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}
  export default likeBar
