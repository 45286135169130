import React from 'react';
import { useParams } from 'react-router-dom';
import b from "./b";

function WrappedB() {
  const { branding } = useParams();
  return (
    <b branding={branding}/>
  );
}

export default WrappedB;