import React, { Component } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Video from "../components/Video";
import Playlist from '../components/Playlist';
import { connect } from 'react-redux';
import { vMetricsActions } from '../actions';
import { userextensionActions } from '../actions';
import { contenthistoryActions } from '../actions';
import { concatCatalogue } from '../helpers/concatCatalogue';
import { concatFrom } from '../helpers/concatFrom';
import { filterWhere } from '../helpers/filterWhere';
import { orderBy } from '../helpers/orderBy';
import { history } from '../helpers/history';
import BlankScreen from '../components/Loading'

class Watch extends Component {
  constructor(props) {
    super(props);
    this.flag = true;
    this.state = {
      redirectSomewhere: null
    }
  }


  componentDidMount() {
    //delete persisted data on first load
    delete this.props.userextensions.items
    delete this.props.vMetrics.items

    //get data for current user extension
    this.props.dispatch(userextensionActions.getAllFiltered(this.props.authentication.user.userId)).then((user)=>{
      if (user && user.redirect) {
        this.setState({ redirectSomewhere: user.redirect });
      }
    });

    //todo - MT - find out what this is for
    //create event listener and a way to remove the listener for video player
    this.removeListener = history.listen((location, action) => {
      if (location.pathname.split("/")[1].toLowerCase() === "watch") {
        this.flag = true;
      }
    });
  }
  componentWillUnmount() {
    this.removeListener();
  }

  render() {
    const { userextensions, vMetrics, brandings } = this.props;
    const { redirectSomewhere } = this.state;

    let items;
    let content;
    let contents;
    let EmbedCodeType;
    let userExtension;
    let ShouldUseBrandedIntro = false;
    
    if (brandings.loading) {
      return (<BlankScreen />);
    }    
    if (redirectSomewhere) {
      return (<Navigate to={redirectSomewhere} />);
    }

    // When all data loaded
    if (userextensions.items && this.props.playlistID && userextensions.items[0].activeSubscriptions) {
      //God function processing
      contents = concatFrom("contents", userextensions.items[0]); //Get a list of all content the user owns
      //deprecated
      items = concatCatalogue(userextensions.items); //Processes into legacy format (pre-database changes)
      EmbedCodeType = userextensions.items[0].activeSubscriptions[0].EmbedCodeType;
      //todo - MT - what is this
      userExtension = concatFrom( //get first active subscription
        "activeSubscriptions",
        userextensions.items[0]
      )[0];
      var course = filterWhere( //Get the current course from the current subscription and course ID
        { id: parseInt(this.props.playlistID) },
        concatFrom(
          "course",
          filterWhere(
            { id: this.props.subscriptionID },
            concatFrom("activeSubscriptions",
              userextensions.items[0]
            ))[0]
        ))[0];
      console.log(course);
      if(course && course.showIntroOutro){
        ShouldUseBrandedIntro = Boolean(course.showIntroOutro);
      }
      let sliders = concatFrom("catalogueitems", filterWhere(
        { id: this.props.subscriptionID },
        concatFrom("activeSubscriptions",
          userextensions.items[0]
        )))
      let catalogueItem
      sliders.map((ci) => {
        ci.slider.slidercourses.map((sc) => {
          if (sc.course.id === parseInt(this.props.playlistID)) {
            catalogueItem = ci
          }
        })
      })

      let slidercourses = catalogueItem.slider.slidercourses
      let slidercourseSeqNo = -1
      slidercourses.map((sc) => {
        if (sc.course.id === parseInt(this.props.playlistID)) {
          slidercourseSeqNo = sc.SeqNo
        }
      })

      let part1 = [];
      let part2 = [];
      orderBy("SeqNo", slidercourses)
      slidercourses.map((sc) => { if (sc.SeqNo - slidercourseSeqNo <= 0) { part1.push(sc) } else { part2.push(sc) } })
      slidercourses = part2.concat(part1)

      let videos = concatFrom("contents", course);
      orderBy("SeqNo", videos)
      let currentVideo = videos[0]
      if (this.props.videoID) {
        videos.map((v) => {
          if (v.id === parseInt(this.props.videoID)) {
            currentVideo = v
          }
        })
      }

      var nexts = {}
      nexts.course = "/watch/" + this.props.subscriptionID + "/" + slidercourses[0].course.id

      const currentVideoIndex = videos.indexOf(currentVideo)
      if (currentVideoIndex + 1 < videos.length) {
        nexts.video = "/watch/" + this.props.subscriptionID + "/" + slidercourses[slidercourses.length - 1].course.id + "/" + videos[currentVideoIndex + 1].id
      } else {
        nexts.video = "/watch/" + this.props.subscriptionID + "/" + slidercourses[0].course.id
      }

      //if no video id is set, find the first video
      if (!this.props.videoID) {
        content = concatFrom("contents", course);
        content = orderBy("SeqNo", content)[0];
      } else {
        //if video id is set, find that video in the current playlist
        content = contents.filter((f) => f.id.toString() === this.props.videoID)[0];
      }
    }
    this.content = content;

    if (content !== undefined && userextensions.items && userextensions.items.length > 0 && this.flag) {
      this.flag = false; //only fire once

      //Catch to make sure there is a subscription, playlist and video ID
      if (userextensions.items[0].id && this.props.playlistID && this.props.subscriptionID) {

        //Get user metrics data (can they watch this video?)
        this.props.dispatch(vMetricsActions.getAll(userextensions.items[0].id, this.props.playlistID, this.props.subscriptionID));
        this.flag2 = true; //Required to progress
      }

    }

    //if vMetrics request has been fired and have recieved a response
    if (userExtension && vMetrics.items && this.flag2) {
      //User is able to watch the video
      this.props.dispatch(contenthistoryActions.update(
        {
          "ActiveFlag": 1,
          "CreatedBy": "System",
          "CreatedOn": userExtension.CurrentTime,
          "ModifiedBy": "System",
          "ModifiedOn": userExtension.CurrentTime,
          "UserExtensionID": userextensions.items[0].id,
          "ContentID": content.id,
          "SubscriptionID": this.props.subscriptionID
        }
      ));
      this.flag2 = false;
    }

    console.log("next", nexts);
    console.log("brandings", brandings.branding);

    return (
      <div className="Watch">
        <Navbar LoggedIn="true" />
        {userextensionActions.loading && <em>Loading playlist...</em>}
        {userextensionActions.error && <span className="text-danger">ERROR: {userextensionActions.error}</span>}
        {content && !this.flag &&
          <div>
            <div className="container">
              <div className="BackButton">
                <Link to={"/"}>
                  <i className="fa fa-arrow-left" style={{ color: brandings.branding && brandings.branding.TextColour }}></i>
                </Link>

                <div className="dropdown ml-1" style={{ float: "right" }}>
                  <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Other Sliders
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {items && items.filter((item) => item.slider.slidercourses.length > 0).sort((i1, i2) => i1.SeqNo - i2.SeqNo).map((item) =>
                      <Link key={item.id} to={"/watch/" + item.slider.subscriptionID + "/" + item.slider.slidercourses.sort((i1, i2) => i1.SeqNo - i2.SeqNo)[0].course.id} className="dropdown-item">{item.slider.Name}</Link>
                    )}
                  </div>
                </div>

                <Link to={nexts.course} className="ml-1" style={{ float: "right" }}>
                  <button type="button" className="btn btn-secondary" style={{ color: brandings.branding && brandings.branding.TextColour }}>Next Course</button>
                </Link>

                <Link to={nexts.video} className="ml-1" style={{ float: "right" }}>
                  <button type="button" className="btn btn-secondary" style={{ color: brandings.branding && brandings.branding.TextColour }}>Next Video</button>
                </Link>

              </div>
              <div className="row" style={{ "paddingTop": "5rem" }}>
                <div className="col-12">
                  <h1 style={{ color: brandings.branding && brandings.branding.PrimaryColour }}>
                    {content.Name}
                  </h1>
                  <h2 className="subHeading test-sh" style={{ color: brandings.branding && brandings.branding.TextColour }}>
                    {course.Title}
                  </h2>
                </div>
              </div>
              <div className="row" style={{ "paddingTop": "2rem" }}>
                <div className="video col-12 col-md-8">
                  <Video type={EmbedCodeType} content={content} ThumbnailURL={"/Thumbnails/" + content.ThumbnailURL} ShouldUseBrandedIntro={ShouldUseBrandedIntro} IntroCode={brandings.branding.IntroCode} OutroCode={brandings.branding.OutroCode}></Video>
                  <div className="col-12" style={{ "marginTop": "2rem" }}>
                    <h2 className="subHeading text-sh" style={{ color: brandings.branding && brandings.branding.TextColour }}> Description </h2>
                    {content.Description}
                  </div>
                </div>

                <div className="clearfix clearfix-playlist"></div>
                <div className="col-12 col-md-4">
                  <Playlist key={course.id} data={course} Name={content.Name} from={course.id} subscriptionID={this.props.subscriptionID} videoID={content.id} userExtensionId={userextensions.items[0].id}></Playlist>
                </div>
              </div>
            </div>
          </div>}
      </div>
    )
  }
}
function mapStateToProps(state) { //chist
  const { userextensions, authentication, vMetrics, brandings } = state;
  return {
    userextensions, authentication, vMetrics, brandings
  };
}

const connectedWatch = connect(mapStateToProps)(Watch);
export default connectedWatch
