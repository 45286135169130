import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import Jumbotron from '../components/Jumbotron';
import { brandingMessage } from '../helpers/brandingMessage.js';
import { connect, useStore } from 'react-redux';
import BlankScreen from '../components/Loading'


class FAQ extends Component {
  render() {
    const { brandings } = this.props;
    if (!brandings.branding) {
      return (<BlankScreen />)
    }
    return (
      <div className="FAQ">
        <Navbar LoggedIn="true" />
        <Jumbotron
          title={brandingMessage(brandings, 'faq_header', 'Frequently Asked Questions')}
          subtitle={brandingMessage(brandings, 'faq_subtitle', '')}
        />
        <div className="container faqContainer" style={{ paddingTop: "10px", color: brandings.branding && brandings.branding.TextColour  }} >
          <div className="row">
            <h3 className="col-12">What requirements do I need on my computer to see the training videos?</h3>
          </div>
          <div className="row">
            <div className="col-6">
              <p style={{color: brandings.branding && brandings.branding.TextColour }}>We require:</p>
              <ul>
                <li>Firefox 59.0 or higher</li>
                <li>Safari 11.0 or higher</li>
                <li>Chrome 68.0 or higher</li>
                <li>Microsoft Edge 41.16299 or higher</li>
                <li>Opera 52.0 or higher</li>
                <li>Javascript enabled</li>
              </ul>
            </div>
            <div className="col-6">
              <p style={{color: brandings.branding && brandings.branding.TextColour }}>We recommend:</p>
              <ul>
                <li>A broadband Internet connection</li>
                <li>The minimum viable connection speed to stream Wistia video reliably is about 768 kilobits per second (equal to .768 megabits per second). We recommend a connection speed of at least 5 megabits per second for an optimal experience with HD capability.</li>
              </ul>
            </div>
          </div>
          <br />
          <h3>How do I view the video in full screen mode?</h3><p style={{color: brandings.branding && brandings.branding.TextColour }}>
            To view a selected video in full screen mode, simply click the monitor button in the bottom right of the video window once the video has begun playing.</p>

          <br />
          <h3>How can I return to normal view?</h3><p style={{color: brandings.branding && brandings.branding.TextColour }}>
            To return to normal view from full screen mode, you can either press the ESC key or click the monitor button in the bottom right of the video window that you clicked to bring up full screen mode.</p>

          <br />
          <h3>How do I pause and restart the video?</h3><p style={{color: brandings.branding && brandings.branding.TextColour }}>
            To pause or restart a selected video, move your cursor inside the video window to bring up the video navigation bar located across the bottom of the video. You can now click the pause button located in the bottom left and also you can click and drag the slider to any part of the video.</p>

          <br />
          <h3>My video doesn’t play straight away, what do I do?</h3><p style={{color: brandings.branding && brandings.branding.TextColour }}>
            If your video does not play straight away, please allow a few seconds for the video to fully load. If the video still does not play after a few seconds, refresh the page.</p>

          <br />
          <h3>All I get is a white box where the video should be – what do I do?</h3><p style={{color: brandings.branding && brandings.branding.TextColour }}>
            A white box can mean that the facility to play embedded videos is blocked by your firewall and an exception needs to be added.  Contact your I.T. Department or system administrator.</p>

          <br />
          <h3>The video stops playing part way through – what do I do?</h3><p style={{color: brandings.branding && brandings.branding.TextColour }}>
            If your video freezes and stops playing part way through, please allow a few seconds for the video to load. If the video does not continue to play after a few seconds, refresh the page.</p>

          <br />
          <h3>How do I watch the video on a mobile device?</h3><p style={{color: brandings.branding && brandings.branding.TextColour }}>
            To watch a selected video on a mobile device, simply follow the same process as you would to watch a video from your computer and tap on the video you'd like to watch.</p>

          <br />
          <h3>What mobile devices will not allow me to watch a video?</h3><p style={{color: brandings.branding && brandings.branding.TextColour }}>
            Our videos are mobile friendly and responsive. They have been  designed to play on most mobile devices including tablets, phones and laptops.</p>
        </div>


      </div>
    )
  }
}
function mapStateToProps(state) {
  const { brandings } = state;
  return {
    brandings
  };
}

const connectedFAQ = connect(mapStateToProps)(FAQ);

export default connectedFAQ;
