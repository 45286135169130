import { slidercourseConstants } from '../constants';

export function slidercourses(state = {}, action) {
  switch (action.type) {

    case slidercourseConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case slidercourseConstants.GETALL_SUCCESS:
      return {
        items: action.slidercourses
      };
    case slidercourseConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case slidercourseConstants.DELETE_REQUEST:
      // add 'deleting:true' property to slidercourse being deleted
      return {
        ...state,
        items: state.items.map(slidercourse =>
          slidercourse.id === action.id
            ? { ...slidercourse, deleting: true }
            : slidercourse
        )
      };
    case slidercourseConstants.DELETE_SUCCESS:
      // remove deleted slidercourse from state
      return {
        items: state.items.filter(slidercourse => slidercourse.id !== action.id)
      };
    case slidercourseConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to slidercourse
      return {
        ...state,
        items: state.items.map(slidercourse => {
          if (slidercourse.id === action.id) {
            // make copy of slidercourse without 'deleting:true' property
            const { deleting, ...slidercourseCopy } = slidercourse;
            // return copy of slidercourse with 'deleteError:[error]' property
            return { ...slidercourseCopy, deleteError: action.error };
          }

          return slidercourse;
        })
      };

    default:
      return state
  }
}
