import React, { Component } from 'react';
import { licenseagreementActions, licenseacceptancehistoryActions } from '../actions';
import { connect } from 'react-redux';

class LicenseAcceptanceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agreed: false,
      licenseagreement: {}
    };
  }

  componentDidMount() {
    this.licenseLoad = new CustomEvent('license-load', {
      bubbles: true
    });
    this.licenseUnload = new CustomEvent('license-unload', {
      bubbles: true
    });

    this.reCheck()
  }

  reCheck = () => {
    this.props.dispatch(licenseagreementActions.getLatest()).then((licenseagreement) => {
      if (licenseagreement.licenseagreements && licenseagreement.licenseagreements.length > 0) {
        licenseagreement = licenseagreement.licenseagreements.sort((agreement1, agreement2) => { return agreement1.licenseagreement.LicenseUpdated > agreement2.licenseagreement.LicenseUpdated })[0];
        this.setState({
          licenseagreement: licenseagreement
        })
        this.props.licenseagreements.reCheck = this.reCheck;
        if (this.props.authentication.loggedIn) {
          this.props.dispatch(licenseacceptancehistoryActions.getAll()).then((licenseacceptancehistories) => {
            if (licenseacceptancehistories.licenseacceptancehistorys) {
              if (licenseacceptancehistories.licenseacceptancehistorys.length > 0) {
                licenseacceptancehistories = licenseacceptancehistories.licenseacceptancehistorys
                if (licenseacceptancehistories[0].LicenseAgreementID != licenseagreement.id) {
                  window.dispatchEvent(this.licenseLoad);
                }
              } else {
                window.dispatchEvent(this.licenseLoad);
              }
            }
          })
        }
      }
    })
  }

  submit = () => {
    if (this.state.agreed) {
      const { authentication } = this.props;
      const { licenseagreement } = this.state;
      window.dispatchEvent(this.licenseUnload);

      if (
        authentication.loggedIn &&  //User is logged in
        authentication.user && //Logged in user actually has a user
        authentication.user.userId && //we have the current userId
        licenseagreement.id //we have the latest licenceagreement that exists
      ) {
        this.props.dispatch(licenseacceptancehistoryActions.create({
          ActiveFlag: 1,
          CreatedBy: "User",
          CreatedOn: new Date(),
          ModifiedBy: "User",
          ModifiedOn: new Date(),
          LicenseAgreementID: licenseagreement.id
        }))
      } else {
        alert("Something went wrong, please login and try again")
      }


    } else {
      alert("You must accept the license agreement before you submit")
    }
  }

  componentWillReceiveProps() {
    this.setState({});
  }

  render() {
    const { AgreementText, LicenseUpdated } = this.state.licenseagreement;
    var formattedDate;
    if (LicenseUpdated) {
      formattedDate = LicenseUpdated.split("T")[0];
      formattedDate = formattedDate.split("-");
      formattedDate = formattedDate[2] + "/" + formattedDate[1] + "/" + formattedDate[0];
    }

    return (
      <div style={{ color: "#333333" }}>
        <div className="modal fade" id="licenseModal" tabIndex="-1" role="dialog" aria-labelledby="licenseModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="licenseModalLabel">License Agreement - {formattedDate}</h5>
              </div>
              <div className="modal-body" dangerouslySetInnerHTML={{ __html: AgreementText }} />
              <div className="modal-footer">
                <div className="container">
                  <div className="row" style={{ display: "block" }}>
                    <div className="float-left">
                      <div className="checkbox">
                        <label>
                          <input type="checkbox"
                            checked={this.state.agreed}
                            onChange={() => { this.setState({ agreed: !this.state.agreed }) }}
                          />
                          I agree to the license agreement
                        </label>
                      </div>
                    </div>
                    <div style={{ float: "right" }}>
                      <button type="button" className="btn btn-secondary" onClick={this.submit}>Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { licenseagreements, licenseacceptancehistorys, authentication } = state;
  return {
    licenseagreements, licenseacceptancehistorys, authentication
  };
}

const connectedLicenseAcceptanceForm = connect(mapStateToProps)(LicenseAcceptanceForm);

export default connectedLicenseAcceptanceForm;
