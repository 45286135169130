import { panelConstants } from '../constants';

export function panels(state = {}, action) {
  switch (action.type) {

    case panelConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case panelConstants.GETALL_SUCCESS:
      return {
        items: action.panels
      };
    case panelConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case panelConstants.DELETE_REQUEST:
      // add 'deleting:true' property to panel being deleted
      return {
        ...state,
        items: state.items.map(panel =>
          panel.id === action.id
            ? { ...panel, deleting: true }
            : panel
        )
      };
    case panelConstants.DELETE_SUCCESS:
      // remove deleted panel from state
      return {
        items: state.items.filter(panel => panel.id !== action.id)
      };
    case panelConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to panel
      return {
        ...state,
        items: state.items.map(panel => {
          if (panel.id === action.id) {
            // make copy of panel without 'deleting:true' property
            const { deleting, ...panelCopy } = panel;
            // return copy of panel with 'deleteError:[error]' property
            return { ...panelCopy, deleteError: action.error };
          }

          return panel;
        })
      };

    default:
      return state
  }
}
