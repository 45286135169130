import { userextensionConstants } from '../constants';

export function userextensions(state = {}, action) {
  switch (action.type) {

    case userextensionConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userextensionConstants.GETALL_SUCCESS:
      return {
        items: action.userextensions
      };
    case userextensionConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

      case userextensionConstants.POLICYOK_REQUEST:
        return state;
      case userextensionConstants.POLICYOK_SUCCESS:
        return state;
      case userextensionConstants.POLICYOK_FAILURE:
        return {
          ...state,
          error: action.error
        };

    case userextensionConstants.GETALLFILTERED_REQUEST:
      return {
        loading: true
      };
    case userextensionConstants.GETALLFILTERED_SUCCESS:
      return {
        items: action.userextensions
      };
    case userextensionConstants.GETALFILTEREDL_FAILURE:
      return {
        error: action.error
      };

    case userextensionConstants.DELETE_REQUEST:
      // add 'deleting:true' property to userextension being deleted
      return {
        ...state,
        items: state.items.map(userextension =>
          userextension.id === action.id
            ? { ...userextension, deleting: true }
            : userextension
        )
      };
    case userextensionConstants.DELETE_SUCCESS:
      // remove deleted userextension from state
      return {
        items: state.items.filter(userextension => userextension.id !== action.id)
      };
    case userextensionConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to userextension
      return {
        ...state,
        items: state.items.map(userextension => {
          if (userextension.id === action.id) {
            // make copy of userextension without 'deleting:true' property
            const { deleting, ...userextensionCopy } = userextension;
            // return copy of userextension with 'deleteError:[error]' property
            return { ...userextensionCopy, deleteError: action.error };
          }

          return userextension;
        })
      };

    default:
      return state
  }
}
