export const userextensionConstants = {
    GETALL_REQUEST: 'USEREXTENSION_GETALL_REQUEST',
    GETALL_SUCCESS: 'USEREXTENSION_GETALL_SUCCESS',
    GETALL_FAILURE: 'USEREXTENSION_GETALL_FAILURE',

    GETALLFILTERED_REQUEST: 'USEREXTENSION_GETALLFILTERED_REQUEST',
    GETALLFILTERED_SUCCESS: 'USEREXTENSION_GETALLFILTERED_SUCCESS',
    GETALLFILTERED_FAILURE: 'USEREXTENSION_GETALLFILTERED_FAILURE',

    DELETE_REQUEST: 'USEREXTENSION_DELETE_REQUEST',
    DELETE_SUCCESS: 'USEREXTENSION_DELETE_SUCCESS',
    DELETE_FAILURE: 'USEREXTENSION_DELETE_FAILURE',

    POLICYOK_REQUEST: 'USEREXTENSION_POLICYOK_REQUEST',
    POLICYOK_SUCCESS: 'USEREXTENSION_POLICYOK_SUCCESS',
    POLICYOK_FAILURE: 'USEREXTENSION_POLICYOK_FAILURE'
};
