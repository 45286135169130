export var settings = { //General quickchange settings
  Catalogue: {
    likesEnabled: false,
    dislikesEnabled: false,
    addEnabled: false,
    likebarEnabled: false,
    hoverDescriptionEnabled: false
  },
  Playlist: {
    timeBarEnabled: false
  },
  Video: {
    defaultType: "Wistia"
  },
  Navbar: {
    Links: [
      {Name: "HOME", Link: "/", Enabled: true},
      {Name: "SEARCH", Link: "/Search", Enabled: true},
      {Name: "HISTORY", Link: "/History", Enabled: true},
      {Name: "FAQ", Link: "/FAQ", Enabled: true},
      {Name: "ANALYSIS", Link: "/Analytics", Enabled: true}
    ],
  },
  Crowisms: {
    Enabled: true
  },
  test:{
    isDemo: true
  },
  brandingDefaults:{
    Opacity: 0.3,
    ForegroundColour: "#ffffff", //navbar
    BackgroundColour: "#000000",
    Wallpaper: "Microw-Background n.jpg",
    TaglineImage: "LearningMadeEasy.png",
    UseTaglineImage: true,
    showSnow: false,
    OutroCode: 'ni9kw5nnay',
    IntroCode: 'gm6jlviibl',
    allowMicrosoftSSO: true,
    allowGoogleSSO: false,
    allowAuth0SSO: false,
    auth0CustomText: "Sign on with Auth0",
    StandardSignOn: true,
    showSmartRecommendations: true,
    showRegisterForm: true,
    PageTitle: "Microw",
    FaviconImage: "microw_favicon n.png",
    Logo: "Microw Landscape n.png",
    PrimaryColour: "#EDEAE3",
    SecondaryColour: "#09304D", //Used on header text
    TextColour: "#ffffff",
    PhoneNo: "03333 449918",
    ResellerName: "MiCrow",
    SliderButtonOpacity: 0.5,
    SliderButtonColour: "#333333",
    brandingmessages: [
      {brandingmessagecode: {Code: "navbar_slogan_before"}, Message: ""},
      {brandingmessagecode: {Code: "navbar_slogan_bold"}, Message: ""},
      {brandingmessagecode: {Code: "navbar_slogan_after_bold"}, Message: ""},
      {brandingmessagecode: {Code: "welcome_header"}, Message: "Welcome to our portal, %name%"},
      {brandingmessagecode: {Code: "welcome_subtitle"}, Message: "Your learning content is below"},
      {brandingmessagecode: {Code: "faq_subtitle"}, Message: ""},
      {brandingmessagecode: {Code: "set_password_header"}, Message: "Nearly done!"},
      {brandingmessagecode: {Code: "set_password_subtitle"}, Message: "Create and enter your new personal password here"},
      {brandingmessagecode: {Code: "register_header"}, Message: "New User Registration"},
      {brandingmessagecode: {Code: "register_subtitle"}, Message: "Please register your details below - make sure you use your company email address (Personal email addresses not accepted)"},
      {brandingmessagecode: {Code: "login_header"}, Message: "Login"},
      {brandingmessagecode: {Code: "login_subtitle"}, Message: "Please log-in"},
      {brandingmessagecode: {Code: "video_already_watched_header"}, Message: ""},
      {brandingmessagecode: {Code: "video_already_watched_subtitle"}, Message: ""},
      {brandingmessagecode: {Code: "catalogue_no_content_message"}, Message: "It looks like your portal is empty right now and you don’t have access to any of our videos.\nBut don’t worry – a quick call to our onboarding team on %contactnumber% will enable us to help you out.\nThe %resellername% Onboarding Team"},
      {brandingmessagecode: {Code: "contact_number"}, Message: "03333 449918"},
      {brandingmessagecode: {Code: "contact_href"}, Message: "tel:+443333449918"},
      {brandingmessagecode: {Code: "faq_title"}, Message: "Frequently Asked Questions"},
      {brandingmessagecode: {Code: "analytics_warning1"}, Message: "Hello there, it looks like you don't have access to this page. - it is for administrators only."},
      {brandingmessagecode: {Code: "analytics_warning2"}, Message: "If you believe you need access please get in touch with %resellername% on %phonenumber%"},
      {brandingmessagecode: {Code: "analytics_header"}, Message: "Analytics"},
      {brandingmessagecode: {Code: "analytics_subtitle"}, Message: "This is where you can view and analyse site access history."},
      {brandingmessagecode: {Code: "reset_header"}, Message: "Password Reset"},
      {brandingmessagecode: {Code: "reset_subtitle"}, Message: "Please enter your email address below"},
      {brandingmessagecode: {Code: "search_header"}, Message: "Search"},
      {brandingmessagecode: {Code: "search_subtitle"}, Message: "Find videos by searching for them"},
      {brandingmessagecode: {Code: "history_header"}, Message: "History"},
      {brandingmessagecode: {Code: "history_subtitle"}, Message: "See what you’ve watched before"}

    ]
  }
}