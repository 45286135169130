import { subscriptiontypeConstants } from '../constants';

export function subscriptiontypes(state = {}, action) {
  switch (action.type) {

    case subscriptiontypeConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case subscriptiontypeConstants.GETALL_SUCCESS:
      return {
        items: action.subscriptiontypes
      };
    case subscriptiontypeConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case subscriptiontypeConstants.DELETE_REQUEST:
      // add 'deleting:true' property to subscriptiontype being deleted
      return {
        ...state,
        items: state.items.map(subscriptiontype =>
          subscriptiontype.id === action.id
            ? { ...subscriptiontype, deleting: true }
            : subscriptiontype
        )
      };
    case subscriptiontypeConstants.DELETE_SUCCESS:
      // remove deleted subscriptiontype from state
      return {
        items: state.items.filter(subscriptiontype => subscriptiontype.id !== action.id)
      };
    case subscriptiontypeConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to subscriptiontype
      return {
        ...state,
        items: state.items.map(subscriptiontype => {
          if (subscriptiontype.id === action.id) {
            // make copy of subscriptiontype without 'deleting:true' property
            const { deleting, ...subscriptiontypeCopy } = subscriptiontype;
            // return copy of subscriptiontype with 'deleteError:[error]' property
            return { ...subscriptiontypeCopy, deleteError: action.error };
          }

          return subscriptiontype;
        })
      };

    default:
      return state
  }
}
