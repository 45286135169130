import { vFactsConstants } from '../constants';
import { vFactsService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const vFactsActions = {
    getAll
};

function getAll(companyName, fromDate, toDate) {
    return dispatch => {
        dispatch(request());

        vFactsService.getAll(companyName, fromDate, toDate)
            .then(
                vFacts =>
                  dispatch(success(vFacts))
                ,
                error => dispatch(failure(error))
            );
    };
    function request() { return { type: vFactsConstants.GETALL_REQUEST } }
    function success(vFacts) { return { type: vFactsConstants.GETALL_SUCCESS, vFacts } }
    function failure(error) { return { type: vFactsConstants.GETALL_FAILURE, error } }
}
