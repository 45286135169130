import { authHeader } from '../helpers';
import { generalConstants } from '../constants';

export const licenseacceptancehistoryService = {
    getAll,
    getById,
    update,
    getByUserExtension,
    create,
    delete: _delete
};

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    console.log('getAll of LicenseAcceptanceHistory running');

    return fetch(generalConstants.SERVER_URL + "/api/LicenseAcceptanceHistories?filter=%7B%22order%22%3A%20%22CreatedOn%20DESC%22%7D", requestOptions).then(handleResponse);
}

function getByUserExtension(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    console.log('getAll of LicenseAcceptanceHistory running');

    return fetch(generalConstants.SERVER_URL + "/api/UserExtensions?filter=%7B%22where%22%3A%20%7B%22APIUserId%22%3A%20" + id + "%7D%2C%20%22include%22%3A%20%7B%22relation%22%3A%20%22licenseacceptancehistorys%22%7D%7D", requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(generalConstants.SERVER_URL + "/api/LicenseAcceptanceHistory/" + id, requestOptions).then(handleResponse);
}

function update(licenseacceptancehistory) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(licenseacceptancehistory)
    };

    console.log('Update of LicenseAcceptanceHistory running');

    return fetch(generalConstants.SERVER_URL + "/api/LicenseAcceptanceHistory/" + licenseacceptancehistory.id, requestOptions).then(handleResponse);;
}

function create(licenseacceptancehistory) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(licenseacceptancehistory)
    };

    console.log('Update of LicenseAcceptanceHistory running');

    return fetch(generalConstants.SERVER_URL + "/api/LicenseAcceptanceHistories", requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    console.log('delete of LicenseAcceptanceHistory running');

    return fetch(generalConstants.SERVER_URL + "/api/LicenseAcceptanceHistory/" + id, requestOptions).then(handleResponse);;
}

function handleResponse(response) {
    if (!response.ok) {
        return Promise.reject(response.statusText);
    }

    return response.json();
}
