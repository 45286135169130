import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { authHeader } from "../helpers/auth-header.js";
import { connect } from 'react-redux';
import { settings } from '../helpers/settings.js';
import { brandingMessage } from '../helpers/brandingMessage.js';
import { generalConstants } from '../constants';
import './Navbar.css';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.navbar = React.createRef();

    this.state = {
      LoggedIn: authHeader().Authorization, //get current Auth token stored in redux state
      username: '',
      password: '',
      brandings: props.brandings
    }

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }
  //Need a function to set LoggedIn to true if they have a valid cookie
  //deprecated
  onSubmit(e) {
    e.preventDefault();
    //Need a function to assign a cookie to the user here
    //this.setState({LoggedIn: true});
  }

  //deprecated
  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  loggedIn(u) {
    if (!u) {
      //Show login/register button if not logged in.
      return [
        //Linda DT-340
        //(<Link className="nav-link body-sm text-sh hl" to="/login" style={{color: this.props.brandings.branding && this.props.brandings.branding.SecondaryColour, opacity: 0.9, fontSize: "12pt"}}>LOGIN</Link>),
        (<Link className="nav-link body-sm text-sh hl" to="/register" style={{ color: this.props.brandings.branding && this.props.brandings.branding.SecondaryColour, opacity: 0.9, fontSize: "12pt" }}>REGISTER</Link>)
      ]
    }

    else {
      //Show log out button if logged in.
      return [(
        <Link className="nav-link body-sm text-sh hl" to="/login" style={{ color: this.props.brandings.branding && this.props.brandings.branding.SecondaryColour, opacity: 0.9, fontSize: "12pt" }}>LOGOUT</Link>
      )]
    }
  }

  componentWillReceiveProps(props) {
    this.setState({});
  }
  /*HTML bin
  
  //Linda DT-340
  <li className="nav-item">
    <a className="nav-link body-sm text-sh hl" href="http://mi-crow.com" style={{color: brandings.branding && brandings.branding.SecondaryColour, opacity: 0.9, fontSize: "12pt"}}>MI-CROW.COM <span className="sr-only">(current)</span></a>
  </li>
  
  */

  shouldUseImageTagline(brandings) {
    if (brandings && !brandings.Loading && brandings.branding) {
      return (
        (
          brandingMessage(brandings, "navbar_slogan_before", "") === "" &&
          brandingMessage(brandings, "navbar_slogan_bold", "") === "Learning " &&
          brandingMessage(brandings, "navbar_slogan_after_bold", "") === " Made Easy"
        ) ||
        brandings.branding.UseTaglineImage
      );
    }

  }

  render() {
    const { brandings } = this.props;
    if(!brandings.branding){
      return (<div></div>)
    }
    return (
      <nav ref={(el) => { if (el && brandings.branding) { el.style.setProperty('background-color', brandings.branding.ForegroundColour, 'important') } }} className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container">
          <div className="row logos">
            <div className="">
              {brandings.branding &&
                <Link className="navbar-brand logo-cont" to="/"><img src={"/img/Branding/" + brandings.branding.Logo} alt="Logo" /></Link>
              }
              {!brandings.branding &&
                <Link className="navbar-brand logo-cont" to="/"><img src={"/img/Branding/" + settings.brandingDefaults.Logo} alt="Logo" /></Link>
              }
            </div>
            <div className="lme">
              {
                this.shouldUseImageTagline(brandings) ?
                  (brandings.branding ?
                    <Link className="navbar-brand logo-cont"  to="/"><img src={"/img/Branding/" + brandings.branding.TaglineImage} alt="Tagline" /></Link> :
                    <Link className="navbar-brand logo-cont"  to="/"><img src={"/img/Branding/" + settings.brandingDefaults.TaglineImage} alt="Tagline" /></Link>) :
                  <h1 className="center" style={{ color: brandings.branding && brandings.branding.SecondaryColour }}>
                    {brandingMessage(brandings, "navbar_slogan_before", "")}
                    <strong>{brandingMessage(brandings, "navbar_slogan_bold", "")}</strong>
                    {brandingMessage(brandings, "navbar_slogan_after_bold", "")}
                  </h1>
              }
            </div>

            {generalConstants.SERVER_URL.includes("test") && <div className="lme mt-0 ml-2 bg-danger testBanner"><h2 className="center pl-1 pr-1 text-light">TEST</h2></div>}

          </div>
          <button className="navbar-toggler black" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              {
                settings.Navbar.Links.map((s, i) => {
                  return s.Enabled && (
                    <li className="nav-item" key={i}>
                      <Link className="nav-link body-sm text-sh hl" to={s.Link} style={{ color: brandings.branding && brandings.branding.SecondaryColour, opacity: 0.9, fontSize: "12pt" }}>
                        {s.Name} <span className="sr-only">(current)</span>
                      </Link>
                    </li>)
                }
                )
              }

              {
                this.loggedIn(this.state.LoggedIn).map((i) => {
                  return (
                    <li className="nav-item" key={i}>
                      {i}
                    </li>
                  )
                })
              }
            </ul>
          </div>

        </div>
      </nav>
    )
  }
}
function mapStateToProps(state) {
  const { brandings } = state;
  return {
    brandings
  };
}

const connectedNavbar = connect(mapStateToProps)(Navbar);

export default connectedNavbar;
