import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import './css/base.css';
import { store } from './helpers';

import WrappedApp from './WrappedApp';

//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
unregister();

const container = document.getElementById('root')
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <WrappedApp />
        </BrowserRouter>
    </Provider>
);

//registerServiceWorker();
