import { authHeader } from '../helpers';
import { generalConstants } from '../constants';

export const userextensionService = {
    getAll,
    getAllFiltered,
    getById,
    update,
    getCurrent,
    delete: _delete,
    policyOk
};

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    console.log('getAll of UserExtension running');

    return fetch(generalConstants.SERVER_URL + "/api/UserExtensions", requestOptions).then(handleResponse);
}

function getAllFiltered(userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    console.log('getAllFiltered of UserExtension running');
    return fetch(generalConstants.SERVER_URL + "/api/UserExtensions?filter=%7B%22where%22%3A%7B%22APIUserId%22%3A%22" + userId + "%22%7D%2C%22include%22%3A%20%7B%22activeSubscriptions%22%3A%7B%22catalogue%22%3A%20%7B%22catalogueitems%22%3A%7B%22slider%22%3A%7B%22slidercourses%22%3A%7B%22course%22%3A%7B%22contents%22%3A%7B%22embedcodes%22%3A%22embedcodetype%22%7D%7D%7D%7D%7D%20%7D%7D%7D%20%7D", requestOptions).then(handleResponse);
}

function getCurrent(userId){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    console.log('getAll of UserExtension running');

    return fetch(generalConstants.SERVER_URL + "/api/UserExtensions?filter=%7B%22where%22%3A%7B%22APIUserId%22%3A%22" + userId + "%22%7D%2C%22include%22%3A%20%22company%22%7D", requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(generalConstants.SERVER_URL + "/api/UserExtensions/" + id, requestOptions).then(handleResponse);
}

function update(userextension) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(userextension)
    };

    console.log('Update of UserExtension running');

    return fetch(generalConstants.SERVER_URL + "/api/UserExtension/" + userextension.id, requestOptions).then(handleResponse);;
}

function policyOk() {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
    };

    console.log('Policy asked, updating UserExtension running');

    return fetch(generalConstants.SERVER_URL + "/api/UserExtensions/policyOk", requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    console.log('delete of UserExtension running');

    return fetch(generalConstants.SERVER_URL + "/api/UserExtension/" + id, requestOptions).then(handleResponse);;
}

function handleResponse(response) {
    if (!response.ok) {
        if(response.status === 401){
            console.log("User is unauthorised")
            window.location.pathname = '/login'
        }
        return Promise.reject(response.statusText);
    }

    return response.json();
}
