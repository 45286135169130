import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Navbar from '../components/Navbar';
import Jumbotron from '../components/Jumbotron';
import BlankScreen from '../components/Loading'
import { passwordreset_logActions } from '../actions';
import { brandingMessage } from '../helpers/brandingMessage.js';

class PasswordResetPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        email: '',
        username: '',
        password: ''
      },
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ submitted: true });
    setTimeout(() => {
      this.setState({ submitted: false });
    }, 300000);
    const { user } = this.state;
    user.username = user.email; //username and email will be the same
    const { dispatch } = this.props;
    if (user.email) {
      dispatch(passwordreset_logActions.update({ Code: user.email }));
    }
  }

  render() {
    const { registering, passwordreset_log, brandings } = this.props;
    const { user, submitted } = this.state;
    //Show blank screen until branding has loaded to stop partners seeing microw branding
    if (!brandings.branding) {
      return (<BlankScreen />)
    }
    return (
      <div>
        <Navbar />
        <Jumbotron
          title={brandingMessage(brandings, "reset_header", "Password Reset")}
          subtitle={brandingMessage(brandings, "reset_subtitle", "Please enter your email address below")}

        />
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-8 col-sm-12">
              <form name="form" onSubmit={this.handleSubmit}>
                <div className={'form-group' + (submitted && !user.email ? ' has-error' : '')}>
                  <label htmlFor="email">Email</label>
                  <input type="email" className="form-control" name="email" value={user.email} onChange={this.handleChange} />
                  {submitted && !user.email &&
                    <div className="help-block">Email address is required</div>
                  }
                </div>
                <div className="form-group">
                  <button className="btn btn-primary" disabled={submitted && user.email} title={(submitted && user.email) ? 'Please wait 5 minutes before requesting a new reset' : ''}>Reset Password</button>
                  {passwordreset_log &&
                    <img alt="Resetting Password..." src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  }
                  <Link to="/login" className="btn btn-link">Cancel</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { registering, user, passwordreset_log } = state.registration;
  const { brandings } = state;
  return {
    registering, user, passwordreset_log, brandings
  };
}

const connectedPasswordResetPage = connect(mapStateToProps)(PasswordResetPage);
export default connectedPasswordResetPage;
