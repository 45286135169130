import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { settings } from '../helpers/settings.js';
import { connect } from 'react-redux';
import { contenthistoryActions } from '../actions';

class ContentItem extends Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      subscriptionID: props.subscriptionID,
      playlistID: props.playlistID,
      data: props.data,
      videoID: props.videoID,
      playlist: props.playlist
    }
  }
  componentWillReceiveProps(newProps) {
    this.state = {
      subscriptionID: newProps.subscriptionID,
      playlistID: newProps.playlistID,
      data: newProps.data,
      videoID: newProps.videoID,
      playlist: newProps.playlist
    }
  }
  itemClick(e, i) {
    if (this.state.isVideo === "isVideo") {
      this.setState({ isVideo: "notVideo" });
    } else {
      this.state.playlist.last.setState({ isVideo: "notVideo" });
      this.setState({ isVideo: "isVideo" });
      this.state.playlist.last = this;
    }
  }

  isANewTile(date) {
    if (date) {
      return Math.ceil((new Date() - new Date(date)) / 86400000) < 28
    }
    else {
      return false
    }
  }


  render() {
    /* href={"/watch/" + this.props.playlistID + "/" + this.state.data.id}*/
    if (this.state.data.id.toString() === this.state.videoID.toString() && !this.state.isVideo) {
      this.state.isVideo = "isVideo";
      this.state.playlist.last = this;
    }
    return (

      <li className={this.state.isVideo} onClick={(e) => this.itemClick(e, this)}>
        <div className="cont">
          <div className="tag">
            <p className="contentItemTitle">{this.state.data.Name}</p>
            <p className="contentItem-badge">
              {this.isANewTile(this.props.data.CreatedOn) && <span>NEW VIDEO</span>}
            </p>
          </div>
          <div className="PlaylistDesc">
            <p>
              {this.state.data.Description}
            </p>
          </div>
          <div className="PlaylistImg">
            <Link to={"/watch/" + this.state.subscriptionID + "/" + this.state.playlistID + "/" + this.state.data.id} id={this.state.data.id} data-toggle="tooltip" title={this.state.data.Code}>
              <img src={"/Thumbnails/" + this.state.data.ThumbnailURL} onError={(e) => { e.target.src = "/Thumbnails/fallback.png"; e.target.onerror = "" }} />
            </Link>


          </div>
        </div>
      </li>
    )
  }
}
function mapStateToProps(state) { //chist
  const { } = state;

  return {

  };
}
const connectedContentItem = connect(mapStateToProps)(ContentItem);
export default connectedContentItem
