import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import Jumbotron from '../components/Jumbotron';
import {settings} from '../helpers/settings.js';

class _404 extends Component {
  render() {
  return (
    <div>
      <Navbar />
      <Jumbotron title="Page not found" subtitle={settings.Crowisms.Enabled ? "Oops – looks like you’ve been blown off course.  Try again or get in touch with our HelpDesk?" : "Sorry, that page doesn't exist"} />
      <div className="container">
        <p>Check the address and try again.</p>
        <br />
        <br />
      </div>
    </div>
    )
  }
}

export default _404
