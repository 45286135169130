import { contenthistoryConstants } from '../constants';

export function contenthistorys(state = {}, action) {
  switch (action.type) {

    case contenthistoryConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case contenthistoryConstants.GETALL_SUCCESS:
      return {
        items: action.contenthistorys
      };
    case contenthistoryConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

      case contenthistoryConstants.UPDATE_REQUEST:
        return {
          loading: true
        };
      case contenthistoryConstants.UPDATE_SUCCESS:
        return {
          items: action.contenthistorys
        };
      case contenthistoryConstants.UPDATE_FAILURE:
        return {
          error: action.error
        };

    case contenthistoryConstants.DELETE_REQUEST:
      // add 'deleting:true' property to contenthistory being deleted
      return {
        ...state,
        items: state.items.map(contenthistory =>
          contenthistory.id === action.id
            ? { ...contenthistory, deleting: true }
            : contenthistory
        )
      };
    case contenthistoryConstants.DELETE_SUCCESS:
      // remove deleted contenthistory from state
      return {
        items: state.items.filter(contenthistory => contenthistory.id !== action.id)
      };
    case contenthistoryConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to contenthistory
      return {
        ...state,
        items: state.items.map(contenthistory => {
          if (contenthistory.id === action.id) {
            // make copy of contenthistory without 'deleting:true' property
            const { deleting, ...contenthistoryCopy } = contenthistory;
            // return copy of contenthistory with 'deleteError:[error]' property
            return { ...contenthistoryCopy, deleteError: action.error };
          }

          return contenthistory;
        })
      };

    default:
      return state
  }
}
