export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    UPDATE_REQUEST: 'USERS_LOG_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'USERS_LOG_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_LOG_UPDATE_FAILURE',


    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    GETHASHEDID_REQUEST: 'USERS_GETHASHEDID_REQUEST',
    GETHASHEDID_SUCCESS: 'USERS_GETHASHEDID_SUCCESS',
    GETHASHEDID_FAILURE: 'USERS_GETHASHEDID_FAILURE'
};
