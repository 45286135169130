import { licenseacceptancehistoryConstants } from '../constants';
import { licenseacceptancehistoryService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const licenseacceptancehistoryActions = {
    getAll,
    getByUserExtension,
    create,
    delete: _delete
};

function getAll() {
    return dispatch => {
        dispatch(request());

        return licenseacceptancehistoryService.getAll()
            .then(
                licenseacceptancehistorys => dispatch(success(licenseacceptancehistorys)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: licenseacceptancehistoryConstants.GETALL_REQUEST } }
    function success(licenseacceptancehistorys) { return { type: licenseacceptancehistoryConstants.GETALL_SUCCESS, licenseacceptancehistorys } }
    function failure(error) { return { type: licenseacceptancehistoryConstants.GETALL_FAILURE, error } }
}

function getByUserExtension(id) {
    return dispatch => {
        dispatch(request());

        return licenseacceptancehistoryService.getByUserExtension(id)
            .then(
                licenseacceptancehistorys => dispatch(success(licenseacceptancehistorys)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: licenseacceptancehistoryConstants.GETALL_REQUEST } }
    function success(licenseacceptancehistorys) {
      if(licenseacceptancehistorys.length > 0){
        licenseacceptancehistorys = licenseacceptancehistorys.licenseacceptancehistorys;
      } else {
        return { type: licenseacceptancehistoryConstants.GETALL_FAILURE, error: {name:"Error", message: "User doesn't exist", failed: true} } //user doesnt exist
      }

      return { type: licenseacceptancehistoryConstants.GETALL_SUCCESS, licenseacceptancehistorys }
    }
    function failure(error) { return { type: licenseacceptancehistoryConstants.GETALL_FAILURE, error } }
}

function create(newRecord){
  return dispatch => {
      dispatch(request(newRecord));

      return licenseacceptancehistoryService.create(newRecord)
          .then(
              user => {
                  dispatch(success());

                  dispatch(alertActions.success("License Accepted"));
                  //dispatch(alertActions.success('Update successful'));
              },
              error => {
                  dispatch(failure(error));
                  dispatch(alertActions.error(error));
              }
          );
  };

  function request(newRecord) { return { type: licenseacceptancehistoryConstants.CREATE_REQUEST, newRecord } }
  function success(newRecord) { return { type: licenseacceptancehistoryConstants.CREATE_SUCCESS, newRecord } }
  function failure(error) { return { type: licenseacceptancehistoryConstants.CREATE_FAILURE, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request());

        licenseacceptancehistoryService.delete(id)
            .then(
                licenseacceptancehistorys => dispatch(success(licenseacceptancehistorys)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: licenseacceptancehistoryConstants.DELETE_REQUEST } }
    function success(licenseacceptancehistorys) { return { type: licenseacceptancehistoryConstants.DELETE_SUCCESS, licenseacceptancehistorys } }
    function failure(error) { return { type: licenseacceptancehistoryConstants.DELETE_FAILURE, error } }
}
