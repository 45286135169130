import { brandingConstants } from '../constants';
import { brandingService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const brandingActions = {
    getAll,
    getByUserId,
    setManually,
    getByName,
    delete: _delete
};

function getAll() {
    return dispatch => {
        dispatch(request());

        brandingService.getAll()
            .then(
                brandings => dispatch(success(brandings)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: brandingConstants.GETALL_REQUEST } }
    function success(brandings) { return { type: brandingConstants.GETALL_SUCCESS, brandings } }
    function failure(error) { return { type: brandingConstants.GETALL_FAILURE, error } }
}

function setManually(branding) {
    return dispatch => {
      dispatch(success(branding));
    };

    function success(brandings) {
    const phoneno = brandings.PhoneNo ? brandings.PhoneNo : '';
    const resellername = brandings.ResellerName ? brandings.ResellerName : '';

      if(brandings.brandingmessages){
        brandings.messages = {};
        brandings.brandingmessages.map((message) => {
          brandings.messages[message.brandingmessagecode.Code] = (message.Message ? message.Message : '').replace('%phonenumber%', phoneno).replace('%resellername%', resellername);
        })
      }

      return { type: brandingConstants.GETBYUSERID_SUCCESS, brandings }
    }
}

function getByName(name) {
  return dispatch => {
      dispatch(request());
      return brandingService.getByName(name)
          .then(
              brandings => dispatch(success(brandings)),
              error => dispatch(failure(error))
          );
  };

  function request() { return { type: brandingConstants.GETBYNAME_REQUEST } }
  function success(brandings) {
    if(brandings.length > 0){
      brandings = brandings[0];
      const phoneno = brandings.PhoneNo ? brandings.PhoneNo : '';
      const resellername = brandings.ResellerName ? brandings.ResellerName : '';

      if(brandings.brandingmessages){
        brandings.messages = {};
        brandings.brandingmessages.map((message) => {
          brandings.messages[message.brandingmessagecode.Code] = (message.Message ? message.Message : '').replace('%phonenumber%', phoneno).replace('%resellername%', resellername);
        })
      }
    } else {
      brandings = {failed: true, reason: "No results", value: brandings}
    }
    return { type: brandingConstants.GETBYNAME_SUCCESS, brandings }
   }
  function failure(error) { return { type: brandingConstants.GETBYNAME_FAILURE, error } }
}

function getByUserId(id) {
  return dispatch => {
      dispatch(request());

      return brandingService.getByUserId(id)
          .then(
              brandings => dispatch(success(brandings)),
              error => dispatch(failure(error))
          );
  };

  function request() { return { type: brandingConstants.GETBYUSERID_REQUEST } }
  function success(brandings) {
    var brandingsFound = false

    if(brandings.length > 0){
      brandings = brandings[0];
      if(brandings.company){
        brandings = brandings.company
        if(brandings.reseller){
          brandings = brandings.reseller
          if(brandings.branding){
            brandings = brandings.branding
            brandingsFound = true;
          } else {
            brandings = {failed: true, reason: "Reseller does not have a branding", value: brandings}
          }
        } else {
          brandings = {failed: true, reason: "Company does not have a reseller", value: brandings}
        }
      } else {
        brandings = {failed: true, reason: "userextension does not have a company", value: brandings}
      }
    } else {
      brandings = {failed: true, reason: "user is not logged in", value: brandings}
    }

    if(brandings.brandingmessages){
      const phoneno = brandings.PhoneNo ? brandings.PhoneNo : '';
      const resellername = brandings.ResellerName ? brandings.ResellerName : '';

      brandings.messages = {};
      brandings.brandingmessages.map((message) => {
        brandings.messages[message.brandingmessagecode.Code] = (message.Message ? message.Message : '').replace('%phonenumber%', phoneno).replace('%resellername%', resellername);
      })
    }

    return { type: brandingConstants.GETBYUSERID_SUCCESS, brandings }

   }
  function failure(error) { return { type: brandingConstants.GETBYUSERID_FAILURE, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request());

        brandingService.delete(id)
            .then(
                brandings => dispatch(success(brandings)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: brandingConstants.DELETE_REQUEST } }
    function success(brandings) { return { type: brandingConstants.DELETE_SUCCESS, brandings } }
    function failure(error) { return { type: brandingConstants.DELETE_FAILURE, error } }
}
