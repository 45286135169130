import React, { Component } from 'react';
import { connect } from 'react-redux';
import { applyBranding } from '../helpers/applyBranding.js';
import { settings } from '../helpers/settings.js';
import { brandingActions } from '../actions';
import { Navigate } from 'react-router-dom';

class Jumbotron extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectSomewhere: null
    };
  }

  componentDidMount() {
    this.props.dispatch(brandingActions.getByName(this.props.match.params.branding)).then((success) => {
      if (!success.brandings || (success.brandings && success.brandings.failed)) {
        this.props.dispatch(brandingActions.setManually(settings.brandingDefaults));
        applyBranding(settings.brandingDefaults, document);
      } else {
        applyBranding(success.brandings, document);
      }
      this.setState({ redirectSomewhere: "/login" });
    })
  }

  render() {
    const {redirectSomewhere} = this.state;

    if (redirectSomewhere) {
      return (<Navigate to={redirectSomewhere} />);
    }
    return (
      <div></div>
    );
  }
}

function mapStateToProps(state) {
  const { brandings } = state;
  return {
    brandings
  };
}
const connectedJumbotron = connect(mapStateToProps)(Jumbotron);
export default connectedJumbotron;