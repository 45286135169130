import React from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import "react-datepicker/dist/react-datepicker.css"

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

// Test data
const data = [['attribute', 'attribute2'], ['value1', 'value2']];

class PivotTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = props;

    }
    componentWillReceiveProps(newProps){
      this.forceUpdate(); //Force state and properties update when component is updated from parent
    }
    render() {

        return (
          <div style={{paddingLeft:"1rem"}}>
            {this.props.DATA &&
            <PivotTableUI
                data={this.props.DATA}
                onChange={s => this.setState(s)}
                cols={["AccessedYear", "AccessedMonth"]}
                rows={["CompanyName"]}
                renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
                {...this.state}
                hiddenAttributes={["id"]}
            />
          }
          </div>
        );
    }
}

export default PivotTable
