import React from 'react';
import { BigPlayButton, Player } from 'video-react';
import '../css/video-react.css';
export default (props) => {
  return (
    <Player
      playsInline
      poster={"/" + props.ThumbnailURL}
      src={props.ContentURL}
      aspectRatio="auto">
      <BigPlayButton position="center" />
    </Player >
  );
};
