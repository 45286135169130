// Takes a raw list of catalogue items and builds a recursive heirarchy tree
export function recurseCatalogue(arr, i=null){
  // Arr should be a flat array of catalogue items
  // i is the current parent

  //turns i into a json object
  if(i===null){
    i={id:null};
  } else {
    if(typeof(i) === "number") {
      i={id:i};
    }
  };

  // filters through arr for only children of i (the parent)
  //var children = arr.filter((x) => {if(x.ParentCatalogueItemID === i.id){return x} else {return false}});
  if(i.id===null){
    var children = arr;
  } else {
    var children = [];
  }

  // adds courses to children as if they were catalogueitems and replaces the catalogue item with the slider if one is present

  if(i.slider){ // console.log(i.slider); alert(i.slider);
      i.slider.slidercourses.map((x) => {
        //console.log(x.SeqNo);
        x.course.SeqNo = x.SeqNo;
        children.push(x.course)
      });
      i.slider.SeqNo = i.SeqNo
      i.slider.catalogueItemID = i.id
      i=i.slider
  } else {
    if(i){};
  }

  // if there are children
  if(children.length !== 0) {
    // sorts children by their sequence number or puts them last if they don't have one
    children.map((s) => {
      if(s.SeqNo === undefined){s.SeqNo = Number.MAX_VALUE}
      if(s.subscriptionSeqNo === undefined){s.subscriptionSeqNo = Number.MAX_VALUE}
      return true;
    })
    children.sort((a,b) => {
      var n = a.subscriptionSeqNo - b.subscriptionSeqNo;
      if (n !== 0) {
          return n;
      }
      return a.SeqNo - b.SeqNo;
    });
    //children.map((s) => {s.Name = (s.SeqNo).toString() + " " + s.Name; return true}); // testing code, appends the sequence number to the beginning of Name

    // add a "children" property to i containing children
    i.children = children.map((x) => {

      // apply this function again to each of those children as the new parent
      return recurseCatalogue(arr, x)
    });
  } else {
    i.children = children;
  }

  // always return i
  return i;
}
