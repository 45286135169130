export const licenseacceptancehistoryConstants = {
    GETALL_REQUEST: 'LICENSEACCEPTANCEHISTORY_GETALL_REQUEST',
    GETALL_SUCCESS: 'LICENSEACCEPTANCEHISTORY_GETALL_SUCCESS',
    GETALL_FAILURE: 'LICENSEACCEPTANCEHISTORY_GETALL_FAILURE',

    DELETE_REQUEST: 'LICENSEACCEPTANCEHISTORY_DELETE_REQUEST',
    DELETE_SUCCESS: 'LICENSEACCEPTANCEHISTORY_DELETE_SUCCESS',
    DELETE_FAILURE: 'LICENSEACCEPTANCEHISTORY_DELETE_FAILURE',

    CREATE_REQUEST: 'LICENSEACCEPTANCEHISTORY_CREATE_REQUEST',
    CREATE_SUCCESS: 'LICENSEACCEPTANCEHISTORY_CREATE_SUCCESS',
    CREATE_FAILURE: 'LICENSEACCEPTANCEHISTORY_CREATE_FAILURE'
};
