import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import Jumbotron from '../components/Jumbotron';
import { connect } from 'react-redux';
import Cookies from 'js-cookie';
import { userActions } from '../actions';
import { brandingActions } from '../actions';
import { settings } from '../helpers/settings.js';
import { applyBranding } from '../helpers/applyBranding.js';
import { generalConstants } from '../constants';
import { Navigate } from 'react-router-dom';

class Success extends Component {
    constructor(props) {
        super(props);

        //If this isn't branding.microw-courses.com/success. Load the data into a subdomain impartial cookie. then come back :)
        let newSubdomain = Cookies.get('brandingSubdomain');
        if (newSubdomain && window.location.host === generalConstants.SERVER_DOMAIN) {
            let tempToken = Cookies.get('access_token')
            let tempID = Cookies.get('userId')
            if (tempToken) {
                Cookies.set('access_token', tempToken, { domain: generalConstants.SERVER_DOMAIN })
            }
            if (tempID) {
                Cookies.set('userId', tempID, { domain: generalConstants.SERVER_DOMAIN })
            }
            // if (newSubdomain.split('.').length > 0) {
            // Cookies.set('brandingSubdomain', '', { domain: newSubdomain });
            // document.location.href = newSubdomain + '/success';
            // }
            // else {
            Cookies.set('brandingSubdomain', '', { domain: generalConstants.SERVER_DOMAIN });
            document.location.href = 'https://' + newSubdomain + '.microw-courses.com/success';
            // }
        }
        else {
            //Log user in using already built login user flow
            let token;
            let userId;
            if (Cookies.get('access_token')) {
                token = (Cookies.get('access_token').split(':')[1].split('.')[0]);
            }
            if (Cookies.get('userId')) {
                userId = (Cookies.get('userId').split(':')[1].split('.')[0]);
            }
            let userObj =
            {
                id: token,
                token: token,
                userId: userId,
            }
            localStorage.setItem('user', JSON.stringify(userObj));
            this.logInUser(userObj);
        }

        this.state = {
            redirectSomewhere: null
        }
    }

    logInUser(user) {
        const { dispatch } = this.props;

        const host = window.location.host;
        let subdomain = host.split('.')[0];

        dispatch(userActions.loginWithMicrosoft(user)).then((user) => {
            if (this.props.authentication.loggedIn && this.props.authentication.user) {
                //Check for license acceptancy
                console.log(this.props.licenseagreements.reCheck)
                if (this.props.licenseagreements.reCheck) {
                    this.props.licenseagreements.reCheck()
                }
                console.log("subdomain is", subdomain)

                if (!host.includes('microw-courses.com')) {
                    subdomain = host;
                }

                else {
                    subdomain = (subdomain.includes(":") || subdomain.includes("test")) ? "www" : subdomain
                }

                console.log("subdomain is", subdomain)

                if (subdomain === "www") {

                    //Check for user's Branding
                    this.props.dispatch(brandingActions.getByUserId(this.props.authentication.user.userId)).then((success) => {
                        if (!success.brandings || (success.brandings && success.brandings.failed)) {
                            this.props.dispatch(brandingActions.setManually(settings.brandingDefaults));
                            //document.body.style.background = "green";
                            applyBranding(settings.brandingDefaults, document)
                        } else {
                            applyBranding(success.brandings, document)
                        }
                    })
                }
            }
            let routeToRedirectTo = "/";
            const localStorageFirstLoad = localStorage.getItem("firstLoad");
            if (localStorageFirstLoad) {
                var first = localStorage.getItem("firstLoad").toLowerCase();
                var page = first.split("/")
                if (page.length > 1) {
                    page = page[1]
                }
                if (page === "watch" || page === "v") {
                    routeToRedirectTo = first;
                }
                localStorage.removeItem("firstLoad");
            }
            console.log("redirect", routeToRedirectTo);
            this.setState({ redirectSomewhere: routeToRedirectTo });
        })
    }

    render() {
        const { redirectSomewhere } = this.state;
        if (redirectSomewhere) {
            return (<Navigate to={redirectSomewhere} />);
        }
        return (
            <div className="FAQ">
                <Navbar LoggedIn="true" />
                <Jumbotron
                    title="Success"
                    subtitle=""
                />
                <div className="container" style={{ "paddingTop": "10px" }}>
                    <div className="row">
                        <h3 className="col-12">Success</h3>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const { loggingIn } = state.authentication;
    const { brandings, authentication, licenseagreements } = state;
    return {
        loggingIn, brandings, authentication, licenseagreements
    };
}

const connectedSuccess = connect(mapStateToProps)(Success);

export default connectedSuccess
