import React from 'react';
import { useParams } from 'react-router-dom';
import Watch from "./Watch";

function WrappedWatch() {
  const { subscriptionID, playlistID, videoID } = useParams();
  return (
    <Watch subscriptionID={subscriptionID} playlistID={playlistID} videoID={videoID}/>
  );
}

export default WrappedWatch;