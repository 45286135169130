export const passwordreset_logConstants = {
    GETALL_REQUEST: 'PASSWORDRESET_LOG_GETALL_REQUEST',
    GETALL_SUCCESS: 'PASSWORDRESET_LOG_GETALL_SUCCESS',
    GETALL_FAILURE: 'PASSWORDRESET_LOG_GETALL_FAILURE',

    UPDATE_REQUEST: 'PASSWORDRESET_LOG_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'PASSWORDRESET_LOG_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PASSWORDRESET_LOG_UPDATE_FAILURE',

    DELETE_REQUEST: 'PASSWORDRESET_LOG_DELETE_REQUEST',
    DELETE_SUCCESS: 'PASSWORDRESET_LOG_DELETE_SUCCESS',
    DELETE_FAILURE: 'PASSWORDRESET_LOG_DELETE_FAILURE'
};
