import { subscriptionConstants } from '../constants';

export function subscriptions(state = {}, action) {
  switch (action.type) {

    case subscriptionConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case subscriptionConstants.GETALL_SUCCESS:
      return {
        items: action.subscriptions
      };
    case subscriptionConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case subscriptionConstants.DELETE_REQUEST:
      // add 'deleting:true' property to subscription being deleted
      return {
        ...state,
        items: state.items.map(subscription =>
          subscription.id === action.id
            ? { ...subscription, deleting: true }
            : subscription
        )
      };
    case subscriptionConstants.DELETE_SUCCESS:
      // remove deleted subscription from state
      return {
        items: state.items.filter(subscription => subscription.id !== action.id)
      };
    case subscriptionConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to subscription
      return {
        ...state,
        items: state.items.map(subscription => {
          if (subscription.id === action.id) {
            // make copy of subscription without 'deleting:true' property
            const { deleting, ...subscriptionCopy } = subscription;
            // return copy of subscription with 'deleteError:[error]' property
            return { ...subscriptionCopy, deleteError: action.error };
          }

          return subscription;
        })
      };

    default:
      return state
  }
}
