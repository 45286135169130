import React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { userextensionActions } from '../actions';
import { brandingMessage } from '../helpers/brandingMessage.js';
import BlankScreen from '../components/Loading'
import Navbar from '../components/Navbar';
import Jumbotron from '../components/Jumbotron';

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    //load last search text from local memory
    const searchText = localStorage.getItem("searchText")
    this.state = {
      searchText: searchText ? searchText : "",
      data: [],
      searchResults: [],
      errors: [],
      redirectSomewhere: null
    };

    delete props.userextensions.items //Delete persisted data

    this.handleChange = this.handleChange.bind(this);
    this.redirectToVideo = this.redirectToVideo.bind(this);
  }

  componentDidMount() {
    //Reload userextension data on page load
    this.props.dispatch(userextensionActions.getAllFiltered(this.props.authentication.user.userId)).then((user) => {
      if (user && user.redirect) {
        this.setState({ redirectSomewhere: user.redirect });
      }
      this.processData()
      this.orderData(this.state.searchText)
    });
  }

  componentWillReceiveProps(props) {
    //Allows page to reload on property changes
    this.orderData(this.state.searchText)
    this.setState({});
  }

  handleChange(event) {
    //Maps all onChange events to the state by name and value pair
    const { name, value } = event.target;

    // on any change, recalculate the ordering of the video list
    if (name === "searchText") {
      this.orderData(value) //use latest value of searchText instead of state
    } else {
      this.orderData(this.state.searchText) // use state
    }

    //on searchText change, update local memory
    if (name === "searchText") {
      localStorage.setItem("searchText", value)
    }

    this.setState({
      [name]: value
    });
  }

  processData() {
    //check we have userextensions, add error to list and return if not
    if (!this.props.userextensions || !this.props.userextensions.items || !this.props.userextensions.items.length === 0) {
      this.state.errors.push({
        reason: "No UserExtensions",
        message: "We're sorry, something seems to have gone wrong. \nLogging in again should fix the problem"
      })
      this.setState({});

      return false
    }

    var data = []
    const subscriptions = this.props.userextensions.items[0].activeSubscriptions;

    subscriptions.map((subscription) => {
      const catalogue = subscription.catalogue
      const catalogueItems = catalogue.catalogueitems
      catalogueItems.map((catalogueItem) => {
        const slider = catalogueItem.slider
        const sliderCourses = slider.slidercourses
        sliderCourses.map((sliderCourse) => {
          const course = sliderCourse.course;
          const contents = course.contents;
          contents.map((video) => {
            data.push({
              videoName: video.Name,
              videoDescription: video.Description,
              videoThumbnailURL: video.ThumbnailURL,
              length: video.Length,
              videoID: video.id,
              courseID: course.id,
              sliderID: slider.id,
              catalogueID: catalogue.id,
              subscriptionID: subscription.id,
              path: "/watch/" + subscription.id + "/" + course.id + "/" + video.id
            })
          })
        })
      })
    })

    //prevent duplicates in data, prioritise the first one found
    data = data.filter((video, index) => data.indexOf(video) === index)

    this.setState({ data: data })
    return data;

  }

  orderData(searchText) {
    var { data } = this.state;
    const lowerText = searchText.toLowerCase()

    this.setState({
      searchResults: data.filter((video) => {
        var searchTrue = false
        if (video.videoName) {
          searchTrue = searchTrue || video.videoName.toLowerCase().includes(lowerText)
        }
        if (video.videoDescription) {
          searchTrue = searchTrue || video.videoDescription.toLowerCase().includes(lowerText)
        }
        if (lowerText === "") {
          searchTrue = false
        }

        return searchTrue

      })
    })
  }

  redirectToVideo(videoPath) {
    this.setState({redirectSomewhere: videoPath});
  }

  formatData() {
    const { searchResults } = this.state;
    var formatted = []

    for (var i = 0; i < 25 && i < searchResults.length; i++) {
      const video = searchResults[i];

      formatted.push((
        <li className="media mt-3 searchTiles" onClick={() => { this.redirectToVideo(video.path); }}>
          <img style={{ height: "64px" }} className="mr-3" src={"/Thumbnails/" + video.videoThumbnailURL} onError={(e) => { e.target.src = "/Thumbnails/fallback.png"; e.target.onerror = "" }} alt="Generic placeholder image" />
          <div className="media-body">
            <h5 className="mt-0 mb-1">{video.videoName}</h5>
            {video.videoDescription}
          </div>
        </li>
      ))
    }

    return formatted
  }

  render() {
    const { brandings } = this.props;
    const { searchText, redirectSomewhere } = this.state;
    //Show blank screen until branding has loaded to stop partners seeing microw branding
    if (!brandings.branding) {
      return (<BlankScreen />)
    }
    if (redirectSomewhere) {
      return (<Navigate to={redirectSomewhere} />);
    }
    return (
      <div>
        <Navbar />
        <Jumbotron
          title={brandingMessage(brandings, "search_header", "Search")}
          subtitle={brandingMessage(brandings, "search_subtitle", "Find videos by searching for them")}
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">@</span>
                </div>
                <input type="search" placeholder="Search" aria-label="Search" className="form-control" name="searchText" value={searchText} onChange={this.handleChange} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <ul className="list-unstyled">
                {this.formatData()}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { userextensions, authentication, brandings } = state;
  return {
    userextensions, authentication, brandings
  };
}

const connectedSearchPage = connect(mapStateToProps)(SearchPage);
export default connectedSearchPage;
