import { catalogueConstants } from '../constants';

export function catalogues(state = {}, action) {
  switch (action.type) {

    case catalogueConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case catalogueConstants.GETALL_SUCCESS:
      return {
        items: action.catalogues
      };
    case catalogueConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case catalogueConstants.DELETE_REQUEST:
      // add 'deleting:true' property to catalogue being deleted
      return {
        ...state,
        items: state.items.map(catalogue =>
          catalogue.id === action.id
            ? { ...catalogue, deleting: true }
            : catalogue
        )
      };
    case catalogueConstants.DELETE_SUCCESS:
      // remove deleted catalogue from state
      return {
        items: state.items.filter(catalogue => catalogue.id !== action.id)
      };
    case catalogueConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to catalogue
      return {
        ...state,
        items: state.items.map(catalogue => {
          if (catalogue.id === action.id) {
            // make copy of catalogue without 'deleting:true' property
            const { deleting, ...catalogueCopy } = catalogue;
            // return copy of catalogue with 'deleteError:[error]' property
            return { ...catalogueCopy, deleteError: action.error };
          }

          return catalogue;
        })
      };

    default:
      return state
  }
}
