import { contenthistoryConstants } from '../constants';
import { contenthistoryService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const contenthistoryActions = {
    getAll,
    update,
    delete: _delete
};

function getAll() {
    return dispatch => {
        dispatch(request());

        return contenthistoryService.getAll()
            .then(
                contenthistorys => dispatch(success(contenthistorys)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: contenthistoryConstants.GETALL_REQUEST } }
    function success(contenthistorys) { return { type: contenthistoryConstants.GETALL_SUCCESS, contenthistorys } }
    function failure(error) { return { type: contenthistoryConstants.GETALL_FAILURE, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request());

        contenthistoryService.delete(id)
            .then(
                contenthistorys => dispatch(success(contenthistorys)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: contenthistoryConstants.DELETE_REQUEST } }
    function success(contenthistorys) { return { type: contenthistoryConstants.DELETE_SUCCESS, contenthistorys } }
    function failure(error) { return { type: contenthistoryConstants.DELETE_FAILURE, error } }
}

function update(contenthistorys) {
    return dispatch => {
        dispatch(request(contenthistorys));

        contenthistoryService.update(contenthistorys)
            .then(
                user => {
                    dispatch(success());
                    //dispatch(alertActions.success('Update successful'));
                },
                error => {
                    dispatch(failure(error));
                    //dispatch(alertActions.error(error));
                }
            );
    };

    function request(contenthistorys) { return { type: contenthistoryConstants.UPDATE_REQUEST, contenthistorys } }
    function success(contenthistorys) { return { type: contenthistoryConstants.UPDATE_SUCCESS, contenthistorys } }
    function failure(error) { return { type: contenthistoryConstants.UPDATE_FAILURE, error } }
}
