import { licenseagreementConstants } from '../constants';
import { licenseagreementService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const licenseagreementActions = {
    getAll,
    getLatest,
    delete: _delete
};

function getAll() {
    return dispatch => {
        dispatch(request());

        licenseagreementService.getAll()
            .then(
                licenseagreements => dispatch(success(licenseagreements)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: licenseagreementConstants.GETALL_REQUEST } }
    function success(licenseagreements) { return { type: licenseagreementConstants.GETALL_SUCCESS, licenseagreements } }
    function failure(error) { return { type: licenseagreementConstants.GETALL_FAILURE, error } }
}

function getLatest() {
    return dispatch => {
        dispatch(request());

        return licenseagreementService.getLatest()
            .then(
                licenseagreements => dispatch(success(licenseagreements)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: licenseagreementConstants.GETALL_REQUEST } }
    function success(licenseagreements) { return { type: licenseagreementConstants.GETALL_SUCCESS, licenseagreements } }
    function failure(error) { return { type: licenseagreementConstants.GETALL_FAILURE, error } }
}

function _delete(id) {
    return dispatch => {
        dispatch(request());

        licenseagreementService.delete(id)
            .then(
                licenseagreements => dispatch(success(licenseagreements)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: licenseagreementConstants.DELETE_REQUEST } }
    function success(licenseagreements) { return { type: licenseagreementConstants.DELETE_SUCCESS, licenseagreements } }
    function failure(error) { return { type: licenseagreementConstants.DELETE_FAILURE, error } }
}
