import React, { Component } from 'react';
import { connect } from 'react-redux';
import { applyBranding } from '../helpers/applyBranding.js';
import { settings } from '../helpers/settings.js';
import { brandingActions, userextensionActions } from '../actions';
import { Navigate } from 'react-router-dom';

class Jumbotron extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectSomewhere: null
    };
  }

  handleBranding() {
    const host = window.location.host;
    let subdomain = host.split('.')[0];

    if (!host.includes('microw-courses.com')) {
      subdomain = host;
    }
    else {
      subdomain = (subdomain.includes(':') || subdomain.includes('test')) ? 'www' : subdomain
    }

    if (subdomain != 'www') {
      this.props.dispatch(brandingActions.getByName(subdomain)).then((success) => {
        if (!success.brandings || (success.brandings && success.brandings.failed)) {
          this.props.dispatch(brandingActions.setManually(settings.brandingDefaults));
          applyBranding(settings.brandingDefaults, document)
        } else {
          applyBranding(success.brandings, document)
        }
      })
    } else {
      if (this.props.authentication && this.props.authentication.loggedIn) {
        this.props.dispatch(brandingActions.getByUserId(this.props.authentication.user.userId)).then((success) => {
          if (!success.brandings || (success.brandings && success.brandings.failed)) {
            this.props.dispatch(brandingActions.setManually(settings.brandingDefaults));
            applyBranding(settings.brandingDefaults, document)
          } else {
            applyBranding(success.brandings, document)
          }
        })
      } else {
        this.props.dispatch(brandingActions.setManually(settings.brandingDefaults));
        applyBranding(settings.brandingDefaults, document)
      }
    }
  }

  componentDidMount() {
    console.log('props', this.props);
    this.props.dispatch(brandingActions.getByName(this.props.branding)).then((success) => {
      if (!success.brandings || (success.brandings && success.brandings.failed)) {
        console.log('Error On Video Direct');
        console.log(success);
        this.handleBranding();
      } else {
        console.log('SUCESS ON VIDEO REDIRECT', success.brandings);
        applyBranding(success.brandings, document)
      }
      if (!this.props.authentication || !this.props.authentication.user || !this.props.authentication.user.userId) {
        this.setState({ redirectSomewhere: '/login' });
      } else {
        this.props.dispatch(userextensionActions.getAllFiltered(this.props.authentication.user.userId)).then((user) => {
          if(user && user.redirect){
            this.setState({redirectSomewhere: user.redirect});
          }
          var response = this.props.userextensions.items
          var video = false
          if (
            response &&
            response.length > 0 &&
            response[0].activeSubscriptions &&
            response[0].activeSubscriptions.length > 0
          ) {
            response[0].activeSubscriptions.map((subv, subi) => {
              if (
                subv &&
                subv.catalogue &&
                subv.catalogue.catalogueitems
              ) {
                subv.catalogue.catalogueitems.map((civ, cii) => {
                  if (
                    civ &&
                    civ.slider &&
                    civ.slider.slidercourses
                  ) {
                    civ.slider.slidercourses.map((scv, sci) => {
                      if (
                        scv &&
                        scv.course &&
                        scv.course.id.toString() === this.props.c.toString() &&
                        scv.course.contents
                      ) {
                        scv.course.contents.map((cov, coi) => {
                          if (
                            cov &&
                            cov.id.toString() === this.props.v.toString()
                          ) {
                            video = {
                              s: subv.id,
                              c: parseInt(this.props.c.toString()),
                              v: parseInt(this.props.v.toString())
                            }
                          }
                        })
                      }
                    })
                  }
                })
              }
            })
          }

          if (video) {
            this.setState({ redirectSomewhere: '/watch/' + video.s + '/' + video.c + '/' + video.v });
          } else {
            this.setState({ redirectSomewhere: '/' });
          }

        });
      }

    })
  }


  render() {
    const { redirectSomewhere } = this.state;
    if (redirectSomewhere) {
      return (<Navigate to={redirectSomewhere} />);
    }
    return (
      <div></div>
    )
  }
}

function mapStateToProps(state) {
  const { brandings, authentication, userextensions } = state;
  return {
    brandings, authentication, userextensions
  };
}

const connectedJumbotron = connect(mapStateToProps)(Jumbotron);

export default connectedJumbotron;
