import { userextensionConstants } from '../constants';
import { userextensionService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const userextensionActions = {
    getAll,
    getAllFiltered,
    delete: _delete,
    getCurrent,
    policyOk
};

function getCurrent(userId) {
  return dispatch => {
      dispatch(request());

      return userextensionService.getCurrent(userId)
          .then(
              userextensions => dispatch(success(userextensions)),
              error => dispatch(failure(error))
          );
  };

  function request() { return { type: userextensionConstants.GETALL_REQUEST } }
  function success(userextensions) { return { type: userextensionConstants.GETALL_SUCCESS, userextensions } }
  function failure(error) { return { type: userextensionConstants.GETALL_FAILURE, error } }
}

function getAll() {
    return dispatch => {
        dispatch(request());

        return userextensionService.getAll()
            .then(
                userextensions => dispatch(success(userextensions)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userextensionConstants.GETALL_REQUEST } }
    function success(userextensions) { return { type: userextensionConstants.GETALL_SUCCESS, userextensions } }
    function failure(error) { return { type: userextensionConstants.GETALL_FAILURE, error } }
}

function policyOk() {
    return dispatch => {
        dispatch(request());

        return userextensionService.policyOk()
            .then(
                policyOk => dispatch(success()),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userextensionConstants.POLICYOK_REQUEST } }
    function success(userextensions) { return { type: userextensionConstants.POLICYOK_SUCCESS, userextensions } }
    function failure(error) { return { type: userextensionConstants.POLICYOK_FAILURE, error } }
}

function getAllFiltered(userId) {
    return dispatch => {
        dispatch(request());

        return userextensionService.getAllFiltered(userId)
            .then(
                userextensions => {
                  dispatch(success(userextensions));
                  if(userextensions.length > 0 && userextensions[0].TempPasswordFlag){
                    return { redirect: '/setpassword'};
                  }
                },
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userextensionConstants.GETALLFILTERED_REQUEST } }
    function success(userextensions) { return { type: userextensionConstants.GETALLFILTERED_SUCCESS, userextensions } }
    function failure(error) { return { type: userextensionConstants.GETALLFILTERED_FAILURE, error } }
}


function _delete(id) {
    return dispatch => {
        dispatch(request());

        return userextensionService.delete(id)
            .then(
                userextensions => dispatch(success(userextensions)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: userextensionConstants.DELETE_REQUEST } }
    function success(userextensions) { return { type: userextensionConstants.DELETE_SUCCESS, userextensions } }
    function failure(error) { return { type: userextensionConstants.DELETE_FAILURE, error } }
}
