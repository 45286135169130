import { companyparameterConstants } from '../constants';

export function companyparameters(state = {}, action) {
  switch (action.type) {

    case companyparameterConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case companyparameterConstants.GETALL_SUCCESS:
      return {
        items: action.companyparameters
      };
    case companyparameterConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case companyparameterConstants.DELETE_REQUEST:
      // add 'deleting:true' property to companyparameter being deleted
      return {
        ...state,
        items: state.items.map(companyparameter =>
          companyparameter.id === action.id
            ? { ...companyparameter, deleting: true }
            : companyparameter
        )
      };
    case companyparameterConstants.DELETE_SUCCESS:
      // remove deleted companyparameter from state
      return {
        items: state.items.filter(companyparameter => companyparameter.id !== action.id)
      };
    case companyparameterConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to companyparameter
      return {
        ...state,
        items: state.items.map(companyparameter => {
          if (companyparameter.id === action.id) {
            // make copy of companyparameter without 'deleting:true' property
            const { deleting, ...companyparameterCopy } = companyparameter;
            // return copy of companyparameter with 'deleteError:[error]' property
            return { ...companyparameterCopy, deleteError: action.error };
          }

          return companyparameter;
        })
      };

    default:
      return state
  }
}
