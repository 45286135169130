import { authHeader } from '../helpers';
import { generalConstants } from '../constants';

export const userService = {
    login,
    logout,
    register,
    getAll,
    getById,
    update,
    delete: _delete,
    getHashedID
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

    return fetch(generalConstants.SERVER_URL + "/api/Users/login", requestOptions)
        .then(response => {
            if (!response.ok) {
                return Promise.reject(response.statusText);
            }

            return response.json();
        })
        .then(user => {
            // login successful if there's a jwt token in the response
            if (user && user.id) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                user.token = user.id;
                localStorage.setItem('user', JSON.stringify(user));
            }

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(generalConstants.SERVER_URL + "/api/Users", requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(generalConstants.SERVER_URL + "/api/Users/" + id, requestOptions).then(handleResponse);
}

function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    return fetch(generalConstants.SERVER_URL + "/api/Users", requestOptions).then(handleResponse);
}

function update(user) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    var test = fetch(generalConstants.SERVER_URL + "/api/Users/reset-password/", requestOptions);
    try {
      test = test.then((r) => {if(r.status === 204) { return r } else { return handleResponse(r)}}); //handleResponse(test) //test.then(handleResponse);
    } catch(e) {
      console.log(e);
    }
    return test
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(generalConstants.SERVER_URL + "/api/Users/" + id, requestOptions).then(handleResponse);
}

function getHashedID() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    return fetch(generalConstants.SERVER_URL + "/api/vAPIUser_HashedIDs?filter=%7B%7D", requestOptions).then(handleResponse);
}



function handleResponse(response) {
    if (!response.ok) {
        return Promise.reject(response.statusText);
    }
    return response.json();
}
