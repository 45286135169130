import { parameteroptionConstants } from '../constants';

export function parameteroptions(state = {}, action) {
  switch (action.type) {

    case parameteroptionConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case parameteroptionConstants.GETALL_SUCCESS:
      return {
        items: action.parameteroptions
      };
    case parameteroptionConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case parameteroptionConstants.DELETE_REQUEST:
      // add 'deleting:true' property to parameteroption being deleted
      return {
        ...state,
        items: state.items.map(parameteroption =>
          parameteroption.id === action.id
            ? { ...parameteroption, deleting: true }
            : parameteroption
        )
      };
    case parameteroptionConstants.DELETE_SUCCESS:
      // remove deleted parameteroption from state
      return {
        items: state.items.filter(parameteroption => parameteroption.id !== action.id)
      };
    case parameteroptionConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to parameteroption
      return {
        ...state,
        items: state.items.map(parameteroption => {
          if (parameteroption.id === action.id) {
            // make copy of parameteroption without 'deleting:true' property
            const { deleting, ...parameteroptionCopy } = parameteroption;
            // return copy of parameteroption with 'deleteError:[error]' property
            return { ...parameteroptionCopy, deleteError: action.error };
          }

          return parameteroption;
        })
      };

    default:
      return state
  }
}
