import { authHeader } from '../helpers';
import { generalConstants } from '../constants';

export const licenseagreementService = {
    getAll,
    getById,
    getLatest,
    update,
    delete: _delete
};

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    console.log('getAll of LicenseAgreement running');

    return fetch(generalConstants.SERVER_URL + "/api/LicenseAgreements", requestOptions).then(handleResponse);
}

function getLatest() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    console.log('getAll of LicenseAgreement running');

    return fetch(generalConstants.SERVER_URL + "/api/LicenseAgreements?filter=%7B%22order%22%3A%20%22LicenseUpdated%20DESC%22%2C%20%22limit%22%3A%201%7D", requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(generalConstants.SERVER_URL + "/api/LicenseAgreement/" + id, requestOptions).then(handleResponse);
}

function update(licenseagreement) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(licenseagreement)
    };

    console.log('Update of LicenseAgreement running');

    return fetch(generalConstants.SERVER_URL + "/api/LicenseAgreement/" + licenseagreement.id, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    console.log('delete of LicenseAgreement running');

    return fetch(generalConstants.SERVER_URL + "/api/LicenseAgreement/" + id, requestOptions).then(handleResponse);;
}

function handleResponse(response) {
    if (!response.ok) {
        return Promise.reject(response.statusText);
    }

    return response.json();
}
