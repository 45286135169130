
export function brandingMessage(brandings, key, alt=''){
  //init
  var msg, branding
  msg = alt

  //If we have a branding
  if(brandings.branding){
    branding = brandings.branding
    //Find the correct message
    if(branding.messages && Object.keys(brandings.branding.messages).includes(key)) {
      msg = brandings.branding.messages[key]

      //If the message is set to nothing, use the alternative
      if(msg === null){
        msg = alt
      }
    }

  //If we dont have a branding
  } else {
    branding = {
    }
  }
  if(!branding.PhoneNo){
      branding.PhoneNo = "03333 449918"
  }
  if(!branding.ResellerName){
    branding.ResellerName = "MiCrow"
  }

  //Replace shortcodes
  msg = msg.replace("%phonenumber%", branding.PhoneNo).replace("%resellername%", branding.ResellerName)

  //Return alt
  return msg
}
