import React, { Component } from 'react';
import Player from './Player';
import TestWistiaPlayer from './WistiaPlayer';

class Vid extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { content, type, ShouldUseBrandedIntro, ThumbnailURL, IntroCode, OutroCode } = this.props
    console.log("brandings", this.props);
    //Make sure there is content with embed codes inside
    if(content && content.embedcodes && content.embedcodes.length > 0){
      //Identify the correct embed code
      var embed = content.embedcodes.filter(s => {return s.embedcodetype.Name === type})
      if (embed.length > 0){
        embed = embed[0]
      } else {
        embed = content.embedcodes[0]
      }

      //Return the correct video player for the embed type
      switch(embed.embedcodetype.Name){
        case "Wistia":
          return (
            <TestWistiaPlayer code={embed.Name} ShouldUseBrandedIntro={ShouldUseBrandedIntro} IntroCode={IntroCode} OutroCode={OutroCode}></TestWistiaPlayer>
          )
        case "Local":
          return (
            <Player ThumbnailURL={"../Thumbnails/" + ThumbnailURL} ContentURL={"/videos/" + content.ContentURL} />
          )
        default:
          return (
            //Display an error in console if there isn't an embed code or a correct embed type
            <div className="alert alert-danger"><strong>No Video Content</strong></div>
          )
      }
    } else {
      console.error("Invalid Video Content");
      return (<div className="alert alert-danger"><strong>Invalid Video Content</strong></div>)
    }
  }
}

export default Vid
