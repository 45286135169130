/*
  Tree walking search function, commented using the
  analogy of files, folders and zip files.
*/


//Concatenates lists from a specific point in a defined json structure
export function concatFrom(level, arr){ "content"
  var newArr = [];
  if(arr === undefined) {return []}
  Object.keys(arr).map((l) => { //{id: 1, content: {}, children: [{id:2, content: {}}]} ["id", "content", "children"]
    //if the name matches
    if(l === level){                            //the file you searched for
      //If the name returned a folder, get all of the files inside of that folder
      if(arr[l] instanceof Array){
        arr[l].map((c) => {newArr.push(c)}); //folder of files you want
      } else {
      //If the name returned a file, get just that file
        newArr.push(arr[l]); //file
      }
    //if the name doesnt match
    } else {
      //if there is a folder that doesn't match, look at all of its containing files as well to see if it has any files/folders you want
      if(arr[l] instanceof Array){              //folder
        arr[l].map((nl) => { //console.log(nl);
          concatFrom(level,nl).map((c) => {
            newArr.push(c)
          })
        });
      //check if the zip file contains any files or folders that match
     } else {
       if(arr[l] instanceof Object){             //file
         concatFrom(level, arr[l]).map((c) => {
           newArr.push(c)
         });
       }
     }
    }
  });
  return newArr;
}
