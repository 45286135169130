import { embedcodetypeConstants } from '../constants';

export function embedcodetypes(state = {}, action) {
  switch (action.type) {

    case embedcodetypeConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case embedcodetypeConstants.GETALL_SUCCESS:
      return {
        items: action.embedcodetypes
      };
    case embedcodetypeConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case embedcodetypeConstants.DELETE_REQUEST:
      // add 'deleting:true' property to embedcodetype being deleted
      return {
        ...state,
        items: state.items.map(embedcodetype =>
          embedcodetype.id === action.id
            ? { ...embedcodetype, deleting: true }
            : embedcodetype
        )
      };
    case embedcodetypeConstants.DELETE_SUCCESS:
      // remove deleted embedcodetype from state
      return {
        items: state.items.filter(embedcodetype => embedcodetype.id !== action.id)
      };
    case embedcodetypeConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to embedcodetype
      return {
        ...state,
        items: state.items.map(embedcodetype => {
          if (embedcodetype.id === action.id) {
            // make copy of embedcodetype without 'deleting:true' property
            const { deleting, ...embedcodetypeCopy } = embedcodetype;
            // return copy of embedcodetype with 'deleteError:[error]' property
            return { ...embedcodetypeCopy, deleteError: action.error };
          }

          return embedcodetype;
        })
      };

    default:
      return state
  }
}
