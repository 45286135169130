import { userparameterConstants } from '../constants';

export function userparameters(state = {}, action) {
  switch (action.type) {

    case userparameterConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case userparameterConstants.GETALL_SUCCESS:
      return {
        items: action.userparameters
      };
    case userparameterConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case userparameterConstants.DELETE_REQUEST:
      // add 'deleting:true' property to userparameter being deleted
      return {
        ...state,
        items: state.items.map(userparameter =>
          userparameter.id === action.id
            ? { ...userparameter, deleting: true }
            : userparameter
        )
      };
    case userparameterConstants.DELETE_SUCCESS:
      // remove deleted userparameter from state
      return {
        items: state.items.filter(userparameter => userparameter.id !== action.id)
      };
    case userparameterConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to userparameter
      return {
        ...state,
        items: state.items.map(userparameter => {
          if (userparameter.id === action.id) {
            // make copy of userparameter without 'deleting:true' property
            const { deleting, ...userparameterCopy } = userparameter;
            // return copy of userparameter with 'deleteError:[error]' property
            return { ...userparameterCopy, deleteError: action.error };
          }

          return userparameter;
        })
      };

    default:
      return state
  }
}
