import { authHeader } from '../helpers';
import { generalConstants } from '../constants';

export const vMetricsService = {
    getAll,
};

function getAll(UserExtensionID, CourseID, subscriptionID) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    console.log(requestOptions);

    return fetch(generalConstants.SERVER_URL + "/api/vCourse_UserMetrics?filter=%7B%22where%22%3A%7B%22userExtensionID%22%3A%20" + UserExtensionID.toString() + "%2C%20%22courseID%22%3A%20" + CourseID.toString() + "%2C%20%22subscriptionID%22%3A" + subscriptionID.toString() + "%7D%7D", requestOptions).then(handleResponse);
}

function handleResponse(response) {
    if (!response.ok) {
        return Promise.reject(response.statusText);
    }
    return response.json();
}
