import React from 'react';
import { useParams } from 'react-router-dom';
import Search from "./Search";

function WrappedSearch() {
  const { text } = useParams();
  return (
    <Search branding={text}/>
  );
}

export default WrappedSearch;