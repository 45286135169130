import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';

// BEGIN NodeJS_Client_reducers_index.js_import
import {brandings} from './branding.reducer';
import {panels} from './panel.reducer';
import {panelbrandings} from './panelbranding.reducer';
import {companys} from './company.reducer';
import {userextensions} from './userextension.reducer';
import {globalparameters} from './globalparameter.reducer';
import {companyparameters} from './companyparameter.reducer';
import {userparameters} from './userparameter.reducer';
import {parameteroptions} from './parameteroption.reducer';
import {subscriptions} from './subscription.reducer';
import {subscriptiontypes} from './subscriptiontype.reducer';
import {subscriptionhistorys} from './subscriptionhistory.reducer';
import {catalogues} from './catalogue.reducer';
import {catalogueitems} from './catalogueitem.reducer';
import {contents} from './content.reducer';
import {contenthistorys} from './contenthistory.reducer';
import {embedcodes} from './embedcode.reducer';
import {embedcodetypes} from './embedcodetype.reducer';
import {parametertypes} from './parametertype.reducer';
import {emailtemplates} from './emailtemplate.reducer';
import {passwordresets} from './passwordreset.reducer';
import {courses} from './course.reducer';
import {sliders} from './slider.reducer';
import {slidercourses} from './slidercourse.reducer';
import {passwordreset_logs} from './passwordreset_log.reducer';
import {vMetrics} from './vMetrics.reducer';
import {vFacts} from './vFacts.reducer';
import {reportdefinitions} from './reportdefinition.reducer';
import {creditusages} from './creditusage.reducer';
import {companydomains} from './companydomain.reducer';
import {resellers} from './reseller.reducer';
import {resellerstatuss} from './resellerstatus.reducer';
import {licenseagreements} from './licenseagreement.reducer';
import {prohibiteddomains} from './prohibiteddomain.reducer';
import {brandingmessages} from './brandingmessage.reducer';
import {brandingmessagecodes} from './brandingmessagecode.reducer';
import {licenseacceptancehistorys} from './licenseacceptancehistory.reducer';

//END NodeJS_Client_reducers_index.js_import

import { alert } from './alert.reducer';

const rootReducer = combineReducers({
  authentication,
  registration,
  users,

// BEGIN NodeJS_Client_reducers_index.js_combine
  brandings,
  panels,
  panelbrandings,
  companys,
  userextensions,
  globalparameters,
  companyparameters,
  userparameters,
  parameteroptions,
  subscriptions,
  subscriptiontypes,
  subscriptionhistorys,
  catalogues,
  catalogueitems,
  contents,
  contenthistorys,
  embedcodes,
  embedcodetypes,
  parametertypes,
  emailtemplates,
  passwordresets,
  courses,
  sliders,
  slidercourses,
  passwordreset_logs,
  vMetrics,
  vFacts,
  licenseagreements,
  licenseacceptancehistorys,

// END NodeJS_Client_reducers_index.js_combine

  alert,
});

export default rootReducer;
