//Concatenates lists from a specific point in a defined json structure
export function concatCatalogue(arr){
  // arr should be a json object
  //console.log(arr);
  // Creates a variable storing a list of lists of active subscriptions
  var newarr = arr.map((a) => {
    return a.activeSubscriptions
  } );

  // Joins all lists together
  newarr = [].concat.apply([], newarr);

  // Creates a list of catalogue items per catalogue
  newarr = newarr.map((a) => {
    var c = a.catalogue.catalogueitems.map((b) => {
      b.slider.subscriptionID = a.id;
      b.ExpiryDate = a.ExpiryDate;
      b.slider.expired = a.MaxCredits <= a.UsedCredits;
      b.subscriptionSeqNo = a.SeqNo;
      //b.slider.MaxCredits = a.MaxCredits;
      //b.slider.UsedCredits = a.UsedCredits;
      return b
    })
    return c
  });

  // Joins all catalogue items together into one big list
  newarr = [].concat.apply([], newarr);
  // returns a big list of catalogue items
  return newarr;
}
