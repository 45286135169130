//Concatenates lists from a specific point in a defined json structure
export function filterWhere(conditions, arr){
  var keys = Object.keys(conditions)
  var newArr = arr.filter((i) =>
    keys.filter((c) =>
      conditions[c].toString() !== i[c].toString()
    ).length === 0
  );
  return newArr;
}
