import { brandingConstants } from '../constants';

export function brandings(state = {}, action) {
  switch (action.type) {

    case brandingConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case brandingConstants.GETALL_SUCCESS:
      return {
        items: action.brandings
      };
    case brandingConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case brandingConstants.GETBYUSERID_REQUEST:
      return {
        loading: true
      };
    case brandingConstants.GETBYUSERID_SUCCESS:
      return {
        branding: action.brandings
      };
    case brandingConstants.GETBYUSERID_FAILURE:
      return {
        error: action.error
      };

    case brandingConstants.GETBYNAME_REQUEST:
      return {
        loading: true
      };
    case brandingConstants.GETBYNAME_SUCCESS:
      return {
        branding: action.brandings
      };
    case brandingConstants.GETBYNAME_FAILURE:
      return {
        error: action.error
      };

    case brandingConstants.DELETE_REQUEST:
      // add 'deleting:true' property to branding being deleted
      return {
        ...state,
        items: state.items.map(branding =>
          branding.id === action.id
            ? { ...branding, deleting: true }
            : branding
        )
      };
    case brandingConstants.DELETE_SUCCESS:
      // remove deleted branding from state
      return {
        items: state.items.filter(branding => branding.id !== action.id)
      };
    case brandingConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to branding
      return {
        ...state,
        items: state.items.map(branding => {
          if (branding.id === action.id) {
            // make copy of branding without 'deleting:true' property
            const { deleting, ...brandingCopy } = branding;
            // return copy of branding with 'deleteError:[error]' property
            return { ...brandingCopy, deleteError: action.error };
          }

          return branding;
        })
      };

    default:
      return state
  }
}
