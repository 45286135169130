import { embedcodeConstants } from '../constants';

export function embedcodes(state = {}, action) {
  switch (action.type) {

    case embedcodeConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case embedcodeConstants.GETALL_SUCCESS:
      return {
        items: action.embedcodes
      };
    case embedcodeConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case embedcodeConstants.DELETE_REQUEST:
      // add 'deleting:true' property to embedcode being deleted
      return {
        ...state,
        items: state.items.map(embedcode =>
          embedcode.id === action.id
            ? { ...embedcode, deleting: true }
            : embedcode
        )
      };
    case embedcodeConstants.DELETE_SUCCESS:
      // remove deleted embedcode from state
      return {
        items: state.items.filter(embedcode => embedcode.id !== action.id)
      };
    case embedcodeConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to embedcode
      return {
        ...state,
        items: state.items.map(embedcode => {
          if (embedcode.id === action.id) {
            // make copy of embedcode without 'deleting:true' property
            const { deleting, ...embedcodeCopy } = embedcode;
            // return copy of embedcode with 'deleteError:[error]' property
            return { ...embedcodeCopy, deleteError: action.error };
          }

          return embedcode;
        })
      };

    default:
      return state
  }
}
