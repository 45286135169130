import React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { userextensionActions } from '../actions';
import { contenthistoryActions } from '../actions';
import { brandingMessage } from '../helpers/brandingMessage.js';
import BlankScreen from '../components/Loading'
import Navbar from '../components/Navbar';
import Jumbotron from '../components/Jumbotron';

class ViewHistoryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      errors: [],
      pageNo: 1,
      maxRows: 10,
      redirectSomewhere: null
    };

    delete props.userextensions.items //Delete persisted data

    this.handleChange = this.handleChange.bind(this);
    this.redirectToVideo = this.redirectToVideo.bind(this);
  }

  componentDidMount() {
    //Reload userextension data on page load
    this.props.dispatch(userextensionActions.getAllFiltered(this.props.authentication.user.userId)).then((user) => {
      if(user && user.redirect){
        this.setState({redirectSomewhere: user.redirect});
      }
      this.props.dispatch(contenthistoryActions.getAll()).then(() => {
        this.processData()
      })
    });
  }

  componentWillReceiveProps(props) {
    //Allows page to reload on property changes
    this.setState({});
  }

  handleChange(event) {
    //Maps all onChange events to the state by name and value pair
    const { name, value } = event.target;

    this.setState({
      [name]: value
    });
  }

  processData() {
    //check we have userextensions
    if (!this.props.userextensions || !this.props.userextensions.items || !this.props.userextensions.items.length === 0) {
      //add error to list and return if not
      this.state.errors.push({
        reason: "No UserExtensions",
        message: "We're sorry, something seems to have gone wrong. \nLogging in again should fix the problem"
      })
      this.setState({});

      return false
    }

    //processing
    var data = []
    const subscriptions = this.props.userextensions.items[0].activeSubscriptions;

    subscriptions.map((subscription) => {
      const catalogue = subscription.catalogue
      const catalogueItems = catalogue.catalogueitems
      catalogueItems.map((catalogueItem) => {
        const slider = catalogueItem.slider
        const sliderCourses = slider.slidercourses
        sliderCourses.map((sliderCourse) => {
          const course = sliderCourse.course;
          const contents = course.contents;
          contents.map((video) => {
            data.push({
              videoName: video.Name,
              videoDescription: video.Description,
              videoThumbnailURL: video.ThumbnailURL,
              length: video.Length,
              videoID: video.id,
              courseID: course.id,
              sliderID: slider.id,
              catalogueID: catalogue.id,
              subscriptionID: subscription.id,
              path: "/watch/" + subscription.id + "/" + course.id + "/" + video.id
            })
          })
        })
      })
    })


    //Lots of duplicates
    var contenthistory = this.props.contenthistorys.items

    //we only need the last time they viewed a video,
    //-- gets only the first occurance of a contentID
    //-- increases efficiency SIGNIFICANTLY
    contenthistory = contenthistory.filter((ch1, index) => contenthistory.find((ch2) => ch1.ContentID === ch2.ContentID) === ch1)


    var historydata = []
    contenthistory.map((ch) => {
      const found = data.find(video => video.videoID === ch.ContentID)
      if (found) {
        found.when = ch.CreatedOn
        historydata.push(found)
      }
    })

    data = historydata

    //prevent duplicates in data, prioritise the first one found
    data = data.filter((video, index) => data.indexOf(video) === index && video != null)

    this.setState({ data: data })
    return data;

  }

  redirectToVideo(videoPath) {
    this.setState({redirectSomewhere: videoPath});
  }

  formatData() {
    const { data, maxRows, pageNo } = this.state;
    var formatted = []

    const beginning = (pageNo - 1) * maxRows
    for (var i = beginning; i < maxRows + beginning && i < data.length; i++) {
      const video = data[i];

      formatted.push((
        <li className="media mt-3 searchTiles" onClick={() => { this.redirectToVideo(video.path); }}>

          <img style={{ height: "64px" }} className="mr-3" src={"/Thumbnails/" + video.videoThumbnailURL} onError={(e) => { e.target.src = "/Thumbnails/fallback.png"; e.target.onerror = "" }} alt="Generic placeholder image" />

          <div className="media-body">
            <div className="float-right badge badge-primary m-1">{this.timeSince(new Date(video.when))} ago</div>

            <h5 className="mt-0 mb-1">{video.videoName}</h5>
            {video.videoDescription}
          </div>
        </li>
      ))
    }

    return formatted
  }

  /* Util Functions */
  timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = Math.floor(seconds / 31536000);

    if (interval >= 1) {
      return interval + " years";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval >= 1) {
      return interval + " months";
    }
    interval = Math.floor(seconds / 86400);
    if (interval >= 1) {
      return interval + " days";
    }
    interval = Math.floor(seconds / 3600);
    if (interval >= 1) {
      return interval + " hours";
    }
    interval = Math.floor(seconds / 60);
    if (interval >= 1) {
      return interval + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  pagination() {
    const { data, pageNo, maxRows } = this.state;
    var options = []

    const pages = Math.ceil(data.length / maxRows)

    if (pageNo - 1 > 0) {
      options.push(<li className="page-item"><button className="page-link" onClick={() => { this.setState({ pageNo: pageNo - 1 }) }}>Prev</button></li>)
    }
    if (pageNo - 3 > 0) {
      options.push(<li className="page-item"><button className="page-link" onClick={() => { this.setState({ pageNo: 1 }) }}>...</button></li>)
    }
    if (pageNo - 2 > 0) {
      options.push(<li className="page-item"><button className="page-link" onClick={() => { this.setState({ pageNo: pageNo - 2 }) }}>{pageNo - 2}</button></li>)
    }
    if (pageNo - 1 > 0) {
      options.push(<li className="page-item"><button className="page-link" onClick={() => { this.setState({ pageNo: pageNo - 1 }) }}>{pageNo - 1}</button></li>)
    }
    if (data.length > 0) {
      options.push(<li className="page-item"><button className="page-link">{pageNo}</button></li>)
    }
    if (pageNo + 1 < pages) {
      options.push(<li className="page-item"><button className="page-link" onClick={() => { this.setState({ pageNo: pageNo + 1 }) }}>{pageNo + 1}</button></li>)
    }
    if (pageNo + 2 < pages) {
      options.push(<li className="page-item"><button className="page-link" onClick={() => { this.setState({ pageNo: pageNo + 2 }) }}>{pageNo + 2}</button></li>)
    }
    if (pageNo + 3 < pages) {
      options.push(<li className="page-item"><button className="page-link" onClick={() => { this.setState({ pageNo: pages - 1 }) }}>...</button></li>)
    }
    if (pageNo + 1 < pages) {
      options.push(<li className="page-item"><button className="page-link" onClick={() => { this.setState({ pageNo: pageNo + 1 }) }}>Next</button></li>)
    }

    return options
  }

  setMaxRows(maxRows) {
    const { data, pageNo } = this.state;
    const pages = Math.ceil(data.length / maxRows)

    var newPageNo = pageNo
    if (pageNo < pages) {
      newPageNo = pages - 1
    }
    if (pageNo < 1) {
      newPageNo = 1
    }

    this.setState({
      maxRows: maxRows,
      pageNo: newPageNo
    })
  }

  render() {
    const { brandings } = this.props;
    const { maxRows, redirectSomewhere } = this.state;

    //Show blank screen until branding has loaded to stop partners seeing microw branding
    if (!brandings.branding) {
      return (<BlankScreen />)
    }
    if (redirectSomewhere) {
      return (<Navigate to={redirectSomewhere} />);
    }
    return (
      <div>
        <Navbar />
        <Jumbotron
          title={brandingMessage(brandings, "history_header", "History")}
          subtitle={brandingMessage(brandings, "history_subtitle", "See what you’ve watched before")}
        />
        <div className="container">
          <div className="row">

            <div className="col-lg-4 col-md-4 col-sm-12">
              <div className="dropdown">
                <button className="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Show per page {maxRows}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <button className="dropdown-item" onClick={(e) => { this.setMaxRows(10) }}>10</button>
                  <button className="dropdown-item" onClick={(e) => { this.setMaxRows(25) }}>25</button>
                  <button className="dropdown-item" onClick={(e) => { this.setMaxRows(50) }}>50</button>
                  <button className="dropdown-item" onClick={(e) => { this.setMaxRows(100) }}>100</button>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">
              <ul className="list-unstyled">

                {this.formatData()}
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8 col-md-8 col-sm-12">

              <nav aria-label="Page navigation">
                <ul className="pagination">
                  {this.pagination()}
                </ul>
              </nav>

            </div>
          </div>

        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { userextensions, contenthistorys, authentication, brandings } = state;
  return {
    userextensions, contenthistorys, authentication, brandings
  };
}

const connectedViewHistoryPage = connect(mapStateToProps)(ViewHistoryPage);
export default connectedViewHistoryPage;
