export const brandingConstants = {
    GETALL_REQUEST: 'BRANDING_GETALL_REQUEST',
    GETALL_SUCCESS: 'BRANDING_GETALL_SUCCESS',
    GETALL_FAILURE: 'BRANDING_GETALL_FAILURE',

    DELETE_REQUEST: 'BRANDING_DELETE_REQUEST',
    DELETE_SUCCESS: 'BRANDING_DELETE_SUCCESS',
    DELETE_FAILURE: 'BRANDING_DELETE_FAILURE',

    GETBYNAME_REQUEST: 'BRANDING_GETBYNAME_REQUEST',
    GETBYNAME_SUCCESS: 'BRANDING_GETBYNAME_SUCCESS',
    GETBYNAME_FAILURE: 'BRANDING_GETBYNAME_FAILURE',

    GETBYUSERID_REQUEST: 'BRANDING_GETBYUSERID_REQUEST',
    GETBYUSERID_SUCCESS: 'BRANDING_GETBYUSERID_SUCCESS',
    GETBYUSERID_FAILURE: 'BRANDING_GETBYUSERID_FAILURE'
};
