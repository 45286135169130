import { parametertypeConstants } from '../constants';

export function parametertypes(state = {}, action) {
  switch (action.type) {

    case parametertypeConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case parametertypeConstants.GETALL_SUCCESS:
      return {
        items: action.parametertypes
      };
    case parametertypeConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case parametertypeConstants.DELETE_REQUEST:
      // add 'deleting:true' property to parametertype being deleted
      return {
        ...state,
        items: state.items.map(parametertype =>
          parametertype.id === action.id
            ? { ...parametertype, deleting: true }
            : parametertype
        )
      };
    case parametertypeConstants.DELETE_SUCCESS:
      // remove deleted parametertype from state
      return {
        items: state.items.filter(parametertype => parametertype.id !== action.id)
      };
    case parametertypeConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to parametertype
      return {
        ...state,
        items: state.items.map(parametertype => {
          if (parametertype.id === action.id) {
            // make copy of parametertype without 'deleting:true' property
            const { deleting, ...parametertypeCopy } = parametertype;
            // return copy of parametertype with 'deleteError:[error]' property
            return { ...parametertypeCopy, deleteError: action.error };
          }

          return parametertype;
        })
      };

    default:
      return state
  }
}
