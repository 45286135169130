import { passwordreset_logConstants } from '../constants';

export function passwordreset_logs(state = {}, action) {
  switch (action.type) {
    case passwordreset_logConstants.UPDATE_REQUEST:
      return {
        loading: true
      };
    case passwordreset_logConstants.UPDATE_SUCCESS:
      return {
        items: action.passwordreset_logs
      };
    case passwordreset_logConstants.UPDATE_FAILURE:
      return {
        error: action.error
      };
    case passwordreset_logConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case passwordreset_logConstants.GETALL_SUCCESS:
      return {
        items: action.passwordreset_logs
      };
    case passwordreset_logConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case passwordreset_logConstants.DELETE_REQUEST:
      // add 'deleting:true' property to passwordreset_log being deleted
      return {
        ...state,
        items: state.items.map(passwordreset_log =>
          passwordreset_log.id === action.id
            ? { ...passwordreset_log, deleting: true }
            : passwordreset_log
        )
      };
    case passwordreset_logConstants.DELETE_SUCCESS:
      // remove deleted passwordreset_log from state
      return {
        items: state.items.filter(passwordreset_log => passwordreset_log.id !== action.id)
      };
    case passwordreset_logConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to passwordreset_log
      return {
        ...state,
        items: state.items.map(passwordreset_log => {
          if (passwordreset_log.id === action.id) {
            // make copy of passwordreset_log without 'deleting:true' property
            const { deleting, ...passwordreset_logCopy } = passwordreset_log;
            // return copy of passwordreset_log with 'deleteError:[error]' property
            return { ...passwordreset_logCopy, deleteError: action.error };
          }

          return passwordreset_log;
        })
      };

    default:
      return state
  }
}
