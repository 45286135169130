import { panelbrandingConstants } from '../constants';

export function panelbrandings(state = {}, action) {
  switch (action.type) {

    case panelbrandingConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case panelbrandingConstants.GETALL_SUCCESS:
      return {
        items: action.panelbrandings
      };
    case panelbrandingConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case panelbrandingConstants.DELETE_REQUEST:
      // add 'deleting:true' property to panelbranding being deleted
      return {
        ...state,
        items: state.items.map(panelbranding =>
          panelbranding.id === action.id
            ? { ...panelbranding, deleting: true }
            : panelbranding
        )
      };
    case panelbrandingConstants.DELETE_SUCCESS:
      // remove deleted panelbranding from state
      return {
        items: state.items.filter(panelbranding => panelbranding.id !== action.id)
      };
    case panelbrandingConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to panelbranding
      return {
        ...state,
        items: state.items.map(panelbranding => {
          if (panelbranding.id === action.id) {
            // make copy of panelbranding without 'deleting:true' property
            const { deleting, ...panelbrandingCopy } = panelbranding;
            // return copy of panelbranding with 'deleteError:[error]' property
            return { ...panelbrandingCopy, deleteError: action.error };
          }

          return panelbranding;
        })
      };

    default:
      return state
  }
}
