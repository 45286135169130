import React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

import Navbar from '../components/Navbar';
import Jumbotron from '../components/Jumbotron';
import BlankScreen from '../components/Loading'
import { userActions } from '../actions';
import { userextensionActions } from '../actions';
import { brandingMessage } from '../helpers/brandingMessage.js';
import { validatePassword } from '../helpers/passwordValidation.js';


class RegisterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {
        email: '',
        username: '',
        password: '',
        password2: '',
        redirectSomewhere: null
      },
      submitted: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  }

  componentWillReceiveProps(props) {
    this.setState({});
  }

  handleSubmit(event) {
    event.preventDefault();

    const { user } = this.state;
    const { dispatch } = this.props;

    //validation
    const invalid = validatePassword(user.password, user.password2);

    if (invalid.length === 0) {

      this.setState({ submitted: true, invalid: false });
      dispatch(userActions.update(
        {
          newPassword: user.password
        }
      )).then(() => {
        this.setState({ redirectSomewhere: '/login' });
      });
    } else {
      this.setState({ invalid: invalid })
    }
  }


  componentDidMount() {
    this.popupOkLoad = new CustomEvent('popupOk-load', {
      bubbles: true
    });
    this.popupOkUnload = new CustomEvent('popupOk-unload', {
      bubbles: true
    });



    this.props.dispatch(userextensionActions.getCurrent(1)).then((data) => {
      if (data.userextensions && data.userextensions.length > 0 && data.userextensions[0].askPolicyOk) {
        window.dispatchEvent(this.popupOkLoad);
      }
    });
  }

  popupOk = () => {
    window.dispatchEvent(this.popupOkUnload);
    this.props.dispatch(userextensionActions.policyOk())
  }

  render() {
    const { brandings } = this.props;
    const { user, submitted,redirectSomewhere } = this.state;
    let textStyle = { color: brandings.branding && brandings.branding.TextColour }

    //Show blank screen until branding has loaded to stop partners seeing microw branding
    if (!brandings.branding) {
      return (<BlankScreen />)
    }
    if (redirectSomewhere) {
      return (<Navigate to={redirectSomewhere} />);
    }
    return (
      <div>
        <Navbar />
        <Jumbotron
          title={brandingMessage(brandings, "set_password_header", "Nearly done!")}
          subtitle={brandingMessage(brandings, "set_password_subtitle", "Create and enter your new personal password here")}
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-8 col-sm-12">
              <form name="form" onSubmit={this.handleSubmit}>

                <div className={'form-group' + (submitted && !user.password ? ' has-error' : '')}>
                  <label htmlFor="password">New Password</label>
                  <input type="password" className="form-control" name="password" value={user.password} onChange={this.handleChange} />
                  {submitted && !user.password &&
                    <div className="help-block">Password is required</div>
                  }
                </div>
                <div className={'form-group' + (submitted && !user.password2 ? ' has-error' : '')}>
                  <label htmlFor="password2">Re-Enter New Password</label>
                  <input type="password" className="form-control" name="password2" value={user.password2} onChange={this.handleChange} />
                  {submitted && (!user.password2 || user.password2 != user.password) &&
                    <div className="help-block">Passwords do not match</div>
                  }
                </div>
                <div className="form-group">
                  <button className="btn btn-primary">Set Password</button>
                  {user.loading &&
                    <img alt="Registering..." src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                  }
                  {this.state.invalid &&
                    <div className="alert alert-warning" style={{ marginTop: 10 }} role="alert">
                      {this.state.invalid.map(reason => (
                        <div> {reason.reason}</div>
                      ))}
                    </div>
                  }
                </div>
              </form>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12">
              <h2 style={textStyle}> Remember! </h2>


              <p style={textStyle}>Your password needs to be a minimum of 8 characters</p>
              <p style={textStyle}>It must contain at least:
                <ul>
                  <li style={textStyle}>1 Uppercase letter</li>
                  <li style={textStyle}>1 Lowercase letter</li>
                  <li style={textStyle}>1 Number</li>
                  <li style={textStyle}>1 Symbol</li>
                </ul>
              </p>
              <p style={textStyle}>The more characters you use, the stronger your password will be.</p>
            </div>
          </div>
        </div>

        <div style={{ color: "#333333" }}>
          <div className="modal fade" id="popupOkModal" tabIndex="-1" role="dialog" aria-labelledby="popupOkModalLabel">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title" id="popupOkModalLabel">We know you weren’t expecting this page, but we’ve enhanced our password policy to provide greater security.</h1>

                </div>
                <div className="modal-body">
                  <h5 className="blackText">What this means for you…</h5>
                  <p className="blackText">We need you to reset your password before you go any further.</p>
                  <p className="blackText">Your new password should include at least 8 characters including one uppercase letter, one lowercase letter, one number and one symbol.</p>
                  <p className="blackText">Once you have reset this, you’ll be asked to log back in using your new password.</p>
                  <p className="blackText">Thank you for doing this - we know that you know how important security is.</p>

                </div>
                <div className="modal-footer">
                  <div className="container">
                    <div className="row" style={{ display: "block" }}>
                      <div style={{ float: "right" }}>
                        <button type="button" className="btn btn-secondary" data-toggle="modal" data-target="#popupOkModal" onClick={this.popupOk}>Ok</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

function mapStateToProps(state) {
  const { registering, user } = state.registration;
  const { authentication, brandings } = state;
  return {
    registering, user, authentication, brandings
  };
}

const connectedRegisterPage = connect(mapStateToProps)(RegisterPage);
export default connectedRegisterPage;
