import { licenseacceptancehistoryConstants } from '../constants';

export function licenseacceptancehistorys(state = {}, action) {
  switch (action.type) {

    case licenseacceptancehistoryConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case licenseacceptancehistoryConstants.GETALL_SUCCESS:
      return {
        items: action.licenseacceptancehistorys
      };
    case licenseacceptancehistoryConstants.GETALL_FAILURE:
      return {
        error: action.error
      };


    case licenseacceptancehistoryConstants.CREATE_REQUEST:
      return {
        loading: true
      };
    case licenseacceptancehistoryConstants.CREATE_SUCCESS:
      return {
        licenseacceptancehistory: action.newRecord
      };
    case licenseacceptancehistoryConstants.CREATE_FAILURE:
      return {
        error: action.error
      };

    case licenseacceptancehistoryConstants.DELETE_REQUEST:
      // add 'deleting:true' property to licenseacceptancehistory being deleted
      return {
        ...state,
        items: state.items.map(licenseacceptancehistory =>
          licenseacceptancehistory.id === action.id
            ? { ...licenseacceptancehistory, deleting: true }
            : licenseacceptancehistory
        )
      };
    case licenseacceptancehistoryConstants.DELETE_SUCCESS:
      // remove deleted licenseacceptancehistory from state
      return {
        items: state.items.filter(licenseacceptancehistory => licenseacceptancehistory.id !== action.id)
      };
    case licenseacceptancehistoryConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to licenseacceptancehistory
      return {
        ...state,
        items: state.items.map(licenseacceptancehistory => {
          if (licenseacceptancehistory.id === action.id) {
            // make copy of licenseacceptancehistory without 'deleting:true' property
            const { deleting, ...licenseacceptancehistoryCopy } = licenseacceptancehistory;
            // return copy of licenseacceptancehistory with 'deleteError:[error]' property
            return { ...licenseacceptancehistoryCopy, deleteError: action.error };
          }

          return licenseacceptancehistory;
        })
      };

    default:
      return state
  }
}
