import { passwordresetConstants } from '../constants';

export function passwordresets(state = {}, action) {
  switch (action.type) {

    case passwordresetConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case passwordresetConstants.GETALL_SUCCESS:
      return {
        items: action.passwordresets
      };
    case passwordresetConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case passwordresetConstants.DELETE_REQUEST:
      // add 'deleting:true' property to passwordreset being deleted
      return {
        ...state,
        items: state.items.map(passwordreset =>
          passwordreset.id === action.id
            ? { ...passwordreset, deleting: true }
            : passwordreset
        )
      };
    case passwordresetConstants.DELETE_SUCCESS:
      // remove deleted passwordreset from state
      return {
        items: state.items.filter(passwordreset => passwordreset.id !== action.id)
      };
    case passwordresetConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to passwordreset
      return {
        ...state,
        items: state.items.map(passwordreset => {
          if (passwordreset.id === action.id) {
            // make copy of passwordreset without 'deleting:true' property
            const { deleting, ...passwordresetCopy } = passwordreset;
            // return copy of passwordreset with 'deleteError:[error]' property
            return { ...passwordresetCopy, deleteError: action.error };
          }

          return passwordreset;
        })
      };

    default:
      return state
  }
}
