import { authHeader } from '../helpers';
import { generalConstants } from '../constants';

export const brandingService = {
    getAll,
    getById,
    update,
    getByUserId,
    getByName,
    delete: _delete
};

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    console.log('getAll of Branding running');

    return fetch(generalConstants.SERVER_URL + "/api/Brandings", requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(generalConstants.SERVER_URL + "/api/Branding/" + id, requestOptions).then(handleResponse);
}

function getByName(name) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(generalConstants.SERVER_URL + "/api/Brandings?filter=%7B%22where%22%3A%20%7B%22Name%22%3A%20%22" + name + "%22%7D%2C%20%22include%22%3A%20%7B%22brandingmessages%22%3A%20%22brandingmessagecode%22%7D%7D", requestOptions).then(handleResponse);
}

function getByUserId(id){
  const requestOptions = {
      method: 'GET',
      headers: authHeader()
  };

  return fetch(generalConstants.SERVER_URL + "/api/UserExtensions?filter=%7B%22where%22%3A%7B%22APIUserId%22%3A" + id + "%7D%2C%22include%22%3A%7B%22company%22%3A%7B%22reseller%22%3A%7B%22branding%22%3A%7B%22brandingmessages%22%3A%22brandingmessagecode%22%7D%7D%7D%7D%7D", requestOptions).then(handleResponse);
}

function update(branding) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(branding)
    };

    console.log('Update of Branding running');

    return fetch(generalConstants.SERVER_URL + "/api/Branding/" + branding.id, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    console.log('delete of Branding running');

    return fetch(generalConstants.SERVER_URL + "/api/Branding/" + id, requestOptions).then(handleResponse);;
}

function handleResponse(response) {
    if (!response.ok) {
        return Promise.reject(response.statusText);
    }

    return response.json();
}
