import React, { Component } from 'react';
import Navbar from '../components/Navbar';
import Catalogue from "../components/Catalogue";
import Jumbotron from '../components/Jumbotron';
import { settings } from '../helpers/settings';


class CourseWatched extends Component {
  render() {
  return (
    <div className="CourseWatched">
      <Navbar />
      <Jumbotron title={"We're sorry" + (settings.Crowisms.Enabled ? "": "") } subtitle={"But you've already watched this course"} />
      <div className="container">
        <h2>
          Perhaps buy another subscription?
        </h2>
        <p>
          Placeholder for further information etc. etc.
        </p>
      </div>

    </div>
  )
  }
}

export default CourseWatched
