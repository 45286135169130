import React, { Component } from 'react';
class DislikeButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dislikes: props.dislikes, //Number of likes
    };
  }
  onClick(e, i) {
    //send dislike here
    alert("component disliked")
  }
  render() {
    if(this.props.enabled){
      return (
        <div className="dislike" onClick={(e) => {return this.onClick(e, this)}}><i className="fa fa-thumbs-down"></i><span className="badge badge-light">{this.state.dislikes}</span></div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}
  export default DislikeButton
