import React, { useEffect } from 'react';
import App from "./App";
import { useLocation } from 'react-router-dom';
import { alertActions } from './actions';
import { connect } from 'react-redux';

function WrappedApp(props) {
    const location = useLocation();
    const pathname = location.pathname;
    const { dispatch } = props;

    useEffect(() => {
        console.log('Pathname changed:', pathname);
        dispatch(alertActions.clear());
      }, [pathname]);

    return (
        <App />
    );
}
function mapStateToProps(state) {
    return {};
}
const connectedWrappedApp = connect(mapStateToProps)(WrappedApp);
export default connectedWrappedApp;
