import { authHeader } from '../helpers';
import { generalConstants } from '../constants';

export const passwordreset_logService = {
    getAll,
    getById,
    update,
    delete: _delete
};

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    console.log('getAll of PasswordReset_Log running');

    return fetch(generalConstants.SERVER_URL + "/api/PasswordReset_Logs", requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(generalConstants.SERVER_URL + "/api/PasswordReset_Log/" + id, requestOptions).then(handleResponse);
}

function update(passwordreset_log) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(passwordreset_log)
    };

    console.log('Update of PasswordReset_Log running');

    return fetch(generalConstants.SERVER_URL + "/api/PasswordReset_Logs", requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    console.log('delete of PasswordReset_Log running');

    return fetch(generalConstants.SERVER_URL + "/api/PasswordReset_Log/" + id, requestOptions).then(handleResponse);;
}

function handleResponse(response) {
    if (!response.ok) {
        return Promise.reject(response.statusText);
    }

    return response.json();
}
