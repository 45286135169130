import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userextensionActions } from '../actions';
import { vFactsActions } from '../actions';
import { brandingMessage } from '../helpers/brandingMessage.js';
import BlankScreen from '../components/Loading'

import Navbar from '../components/Navbar';
import Jumbotron from '../components/Jumbotron';
import PivotTable from '../components/PivotTable';
import DropdownField from '../components/DropdownField.jsx';
import DatePicker from 'react-datepicker';

class Analytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFrom: new Date("2023-01-01"),
      dateTo: new Date(),
      company: undefined,
      companies: undefined,
      pivot: false
    }
    this.flag = true; //Used to run code after recieving a response from redux/loopback dispatch. This is depreciated as I have found a better method in appDB v2

  }

  //get current user extension (for checking permissions)
  componentDidMount() {
    this.props.dispatch(userextensionActions.getCurrent(this.props.authentication.user.userId));
  }

  //Get data on go click
  goClicked() {
    if (this.state.GlobalAdmin) {
      this.props.dispatch(vFactsActions.getAll(undefined, this.state.dateFrom.toJSON(), this.state.dateTo.toJSON()))
    } else {
      if (this.state.company && this.state.company.Name) {
        this.props.dispatch(vFactsActions.getAll(this.state.company.Name, this.state.dateFrom.toJSON(), this.state.dateTo.toJSON()))
      } else {
        console.log(this.state.company)
      }
    }
  }

  render() {
    const { userextensions, vFacts, companys, brandings } = this.props;
    if (userextensions.items && userextensions.items.length != 0 && this.flag && !userextensions.items[0].activeSubscriptions) {

      this.setState({
        company: userextensions.items[0].company
      });

      this.flag = false;
      if (userextensions.items[0].GlobalAdmin) { 
        this.setState({
          GlobalAdmin: true
        })
      } else {
        this.setState({
          companies: [userextensions.items[0].company],
          GlobalAdmin: false

        })
      }
      if (userextensions.items[0].CompanyAdmin) {
        this.setState({
          CompanyAdmin: true
        })
      } else {
        this.setState({
          CompanyAdmin: false
        })
      }
    }
    if (companys.items && this.flag2) {
      this.setState({
        companies: companys.items
      })
      this.flag2 = false;
    }
    if (vFacts.items) {
      //Jerry rig to change datetime format in data
      vFacts.items.map((d) => { d.Accessed = d.Accessed.split("T")[0] })
    }
    //Show blank screen until branding has loaded to stop partners seeing microw branding
    if (!brandings.branding) {
      return (<BlankScreen />)
    }

    const textStyle = { color: brandings.branding && brandings.branding.TextColour }

    console.log("AnalyticsItems", vFacts.items);
    //if user has permissions
    if ((this.state.CompanyAdmin && userextensions.items && userextensions.items.length > 0 && userextensions.items[0].company) || this.state.GlobalAdmin) {
      return (
        <div className="Analytics">
          <Navbar />

          <div className='HideOnPrint'>
            <Jumbotron
              title={brandingMessage(this.props.brandings, "analytics_header", "Analytics")}
              subtitle={brandingMessage(this.props.brandings, "anayltics_subtitle", "This is where you can view and analyse site access history.")}
            />
          </div>
          <div className="container d-block d-sm-none HideOnPrint">
            <p> Sorry, please view this page on a larger screen. </p>
          </div>
          <div className="container d-none d-sm-block HideOnPrint">
            <div className="row">
              <div className="col-12 col-md-8">
                <div className="row">
                  <div className="col-12">
                    <h3>Select Date Range</h3>
                    {false && <DropdownField
                      values={this.state.companies}
                      curValue={this.state.company && this.state.company.id}
                      handler={(c) => { this.setState({ company: c.value }) }}
                      displayField={"Name"}
                      linkField={"id"}
                    />}
                  </div>
                </div>

                <div className="row analyticsDatepicker">
                  <div className="col-4 datepickcss">
                    <DatePicker
                      selected={this.state.dateFrom}
                      onChange={(d) => { this.setState({ dateFrom: d }) }}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                  <div className="text-center col-1" > to </div>
                  <div className="col-4 datepickcss">
                    <DatePicker
                      selected={this.state.dateTo}
                      onChange={(d) => { this.setState({ dateTo: d }) }}
                      dateFormat="dd/MM/yyyy"
                    />
                  </div>
                  <div className="col-3 analyticsActions">
                    <button className="btn btn-primary whiteText text-bd body-md btn-sm" onClick={() => { this.goClicked() }} disabled={!typeof (this.state.company) === 'object'}> Go </button>
                    <button disabled={!vFacts.items} title={!vFacts.items ? "Please run a report" : ""} className="btn btn-primary whiteText text-bd body-md btn-sm" onClick={() => { window.print() }} > Download (.PDF)</button>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
          <div className="d-none d-sm-block" >
            <PivotTable DATA={

              vFacts.items

            } />
          </div>
        </div>
      )
    } else {
      //user does not have permissions
      return (
        <div className="Analytics">
          <Navbar />
          <Jumbotron
            title={brandingMessage(this.props.brandings, "analytics_header", "Analytics")}
            subtitle={brandingMessage(this.props.brandings, "anayltics_subtitle", "This is where you can view and analyse site access history.")}
          />
          <div className="container">
            <p style={textStyle}> {brandingMessage(this.props.brandings, "analytics_warning1", "Hello there, it looks like you don't have access to this page. - it is for administrators only.")}</p>
            <p style={textStyle}> {brandingMessage(this.props.brandings, "analytics_warning2", "If you believe you need access please get in touch with %resellername% on %phonenumber%")} </p>
          </div>
        </div>
      )
    }

  }
}
function mapStateToProps(state) {
  const { userextensions, authentication, vFacts, companys, brandings } = state;
  console.log(state);
  return {
    userextensions, authentication, vFacts, companys, brandings
  };
}
const ConnectedAnalytics = connect(mapStateToProps)(Analytics);
export default ConnectedAnalytics
