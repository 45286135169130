import { subscriptionhistoryConstants } from '../constants';

export function subscriptionhistorys(state = {}, action) {
  switch (action.type) {

    case subscriptionhistoryConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case subscriptionhistoryConstants.GETALL_SUCCESS:
      return {
        items: action.subscriptionhistorys
      };
    case subscriptionhistoryConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case subscriptionhistoryConstants.DELETE_REQUEST:
      // add 'deleting:true' property to subscriptionhistory being deleted
      return {
        ...state,
        items: state.items.map(subscriptionhistory =>
          subscriptionhistory.id === action.id
            ? { ...subscriptionhistory, deleting: true }
            : subscriptionhistory
        )
      };
    case subscriptionhistoryConstants.DELETE_SUCCESS:
      // remove deleted subscriptionhistory from state
      return {
        items: state.items.filter(subscriptionhistory => subscriptionhistory.id !== action.id)
      };
    case subscriptionhistoryConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to subscriptionhistory
      return {
        ...state,
        items: state.items.map(subscriptionhistory => {
          if (subscriptionhistory.id === action.id) {
            // make copy of subscriptionhistory without 'deleting:true' property
            const { deleting, ...subscriptionhistoryCopy } = subscriptionhistory;
            // return copy of subscriptionhistory with 'deleteError:[error]' property
            return { ...subscriptionhistoryCopy, deleteError: action.error };
          }

          return subscriptionhistory;
        })
      };

    default:
      return state
  }
}
