import { companyConstants } from '../constants';

export function companys(state = {}, action) {
  switch (action.type) {

    case companyConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case companyConstants.GETALL_SUCCESS:
      return {
        items: action.companys
      };
    case companyConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case companyConstants.DELETE_REQUEST:
      // add 'deleting:true' property to company being deleted
      return {
        ...state,
        items: state.items.map(company =>
          company.id === action.id
            ? { ...company, deleting: true }
            : company
        )
      };
    case companyConstants.DELETE_SUCCESS:
      // remove deleted company from state
      return {
        items: state.items.filter(company => company.id !== action.id)
      };
    case companyConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to company
      return {
        ...state,
        items: state.items.map(company => {
          if (company.id === action.id) {
            // make copy of company without 'deleting:true' property
            const { deleting, ...companyCopy } = company;
            // return copy of company with 'deleteError:[error]' property
            return { ...companyCopy, deleteError: action.error };
          }

          return company;
        })
      };

    default:
      return state
  }
}
