export function validatePassword(password, passwordConfirm){
    var invalid = [];
        if( !password ) {
          invalid.push({reason: "Password cannot be empty", code: 1})
        } if (passwordConfirm && password !== passwordConfirm) {
          invalid.push({reason: "Passwords must match", code: 2})
        } if ( password.length < 8) {
          invalid.push({reason: "Password must be at least 8 characters long", code: 3})
        } if ( password.toLowerCase() === password) {
          invalid.push({reason: "Password must contain an upper case letter", code: 4})
        } if ( password.toUpperCase() === password) {
          invalid.push({reason: "Password must contain a lower case letter", code: 5})
        } if ( password.search("[0-9]") < 0 ) {
          invalid.push({reason: "Password must contain at least 1 number", code: 13})
        } if ( password.search("[!@#$%^&*]") < 0 ) {
          invalid.push({reason: "Password must contain at least 1 symbol", code: 11})
        } if ( password.length > 40 ) {
          invalid.push({reason: "Password cannot be greater than 40 characters", code: 12})
        }
    return invalid;
}