import { globalparameterConstants } from '../constants';

export function globalparameters(state = {}, action) {
  switch (action.type) {

    case globalparameterConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case globalparameterConstants.GETALL_SUCCESS:
      return {
        items: action.globalparameters
      };
    case globalparameterConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case globalparameterConstants.DELETE_REQUEST:
      // add 'deleting:true' property to globalparameter being deleted
      return {
        ...state,
        items: state.items.map(globalparameter =>
          globalparameter.id === action.id
            ? { ...globalparameter, deleting: true }
            : globalparameter
        )
      };
    case globalparameterConstants.DELETE_SUCCESS:
      // remove deleted globalparameter from state
      return {
        items: state.items.filter(globalparameter => globalparameter.id !== action.id)
      };
    case globalparameterConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to globalparameter
      return {
        ...state,
        items: state.items.map(globalparameter => {
          if (globalparameter.id === action.id) {
            // make copy of globalparameter without 'deleting:true' property
            const { deleting, ...globalparameterCopy } = globalparameter;
            // return copy of globalparameter with 'deleteError:[error]' property
            return { ...globalparameterCopy, deleteError: action.error };
          }

          return globalparameter;
        })
      };

    default:
      return state
  }
}
