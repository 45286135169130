import React, { Component } from 'react';

class BlankScreen extends Component {
  constructor(props) {
    super(props);
  }

  render() {
      return (
      <div className="blankScreen">
        <img alt="Logging in..." src="../img/loading.gif" />
      </div>
      )
  }
}

export default BlankScreen