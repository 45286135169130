import { vMetricsConstants } from '../constants';
import { vMetricsService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const vMetricsActions = {
    getAll
};

function getAll(UserExtensionID, CourseID, subscriptionID) {
    return dispatch => {
        dispatch(request());

        vMetricsService.getAll(UserExtensionID, CourseID, subscriptionID)
            .then(
                vMetrics =>
                  dispatch(success(vMetrics))
                ,
                error => dispatch(failure(error))
            );
    };
    function request() { return { type: vMetricsConstants.GETALL_REQUEST } }
    function success(vMetrics) { return { type: vMetricsConstants.GETALL_SUCCESS, vMetrics } }
    function failure(error) { return { type: vMetricsConstants.GETALL_FAILURE, error } }
}
