import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { settings } from '../helpers/settings.js';
import { applyBranding } from '../helpers/applyBranding.js';
import { brandingMessage } from '../helpers/brandingMessage.js';
import { generalConstants } from '../constants';
import Cookies from 'js-cookie';

import Navbar from '../components/Navbar';
import Jumbotron from '../components/Jumbotron';
import BlankScreen from '../components/Loading'

import { userActions } from '../actions';
import { brandingActions } from '../actions';
import { Navigate } from 'react-router-dom';

class LoginPage extends React.Component {
  domain = 'microw-courses.com'

  constructor(props) {
    super(props);

    // reset login status
    this.props.dispatch(userActions.logout());

    this.state = {
      username: '', //this.props.username,
      password: '', //this.props.password,
      submitted: false,
      showStandardSignOnAnyway: false, //when only SSO options are set in branding, this flag lets users show normal login options
      redirectSomewhere: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    //If redirected to default branding by failed microsoft SSO, make sure it redirects to branded login
    let brandingSubdomain = Cookies.get('brandingSubdomain')
    if (brandingSubdomain && document.location.href === 'https://' + generalConstants.SERVER_DOMAIN + '/login') {
      Cookies.set('brandingSubdomain', '', { domain: generalConstants.SERVER_DOMAIN });
      document.location.href = 'https://' + brandingSubdomain + '.microw-courses.com/login'
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username, password } = this.state;
    const { dispatch } = this.props;
    if (username && password) {
      dispatch(userActions.login(username, password)).then((login) => {
        if (this.props.authentication.loggedIn && this.props.authentication.user) {

          //Check for license acceptancy
          console.log(this.props.licenseagreements.reCheck)
          if (this.props.licenseagreements.reCheck) {
            this.props.licenseagreements.reCheck()
          }

          var subdomain = window.location.host.split('.')[0]
          //if localhost or test site, assume www as subdomain
          subdomain = (subdomain.includes(":") || subdomain.includes("test")) ? "www" : subdomain

          if (window.location.hostname.split('.')[1] !== 'microw-courses') {
            subdomain = window.location.hostname
          }

          if (subdomain === "www") {

            //Check for user's Branding
            this.props.dispatch(brandingActions.getByUserId(this.props.authentication.user.userId)).then((success) => {
              if (!success.brandings || (success.brandings && success.brandings.failed)) {
                this.props.dispatch(brandingActions.setManually(settings.brandingDefaults));
                applyBranding(settings.brandingDefaults, document)
              } else {
                applyBranding(success.brandings, document)
              }
            });
          }

          const isReset = this.props.isReset === 'reset';
          let routeToRedirectTo = "/";
          if (!isReset) {
            const localStorageFirstLoad = localStorage.getItem("firstLoad");
            if (localStorageFirstLoad) {
              var first = localStorage.getItem("firstLoad").toLowerCase();
              var page = first.split("/")
              if (page.length > 1) {
                page = page[1]
              }
              if (page === "watch" || page === "v") {
                routeToRedirectTo = first;
              }
              localStorage.removeItem("firstLoad");
            }
          } else {
            routeToRedirectTo = "'/SetPassword";
          }
          this.setState({redirectSomewhere: routeToRedirectTo});
        }
      });
    }
  }

  singleSignOn(protocol) {
    let subdomain = window.location.host.split('.')[0]

    if (!window.location.hostname.includes('microw-courses')) {
      subdomain = window.location.hostname
    }
    if (subdomain === 'microw-courses') {
      subdomain = '';
    }
    Cookies.set('brandingSubdomain', subdomain, { domain: generalConstants.SERVER_DOMAIN })

    let domain = window.location.host.split('.')[1];
    if (domain !== 'microw-courses' && domain !== 'com') {
      document.location.href = window.location.origin + '/auth/' + domain
    }
    else {
      document.location.href = 'https://' + generalConstants.SERVER_DOMAIN + '/auth/' + protocol
    }
  }

  revealPasswordToggle() {
    let passwordInput = document.getElementById("passwordInput");
    let eyeButton = document.getElementById("revealPasswordButton");

    console.log(eyeButton.className);

    if (eyeButton.className === "toggle-password ml-2 fa fa-eye") {
      eyeButton.className = "toggle-password ml-2 fa fa-eye-slash"
      passwordInput.type = "input"
    }
    else {
      eyeButton.className = "toggle-password ml-2 fa fa-eye"
      passwordInput.type = "password"
    }
  }

  showStandardSignOn() {
    this.showStandardSignOnAnyway = true;
    this.setState({ ...this.state, showStandardSignOnAnyway: true })
  }

  render() {
    const { loggingIn, brandings } = this.props;
    const { submitted, showStandardSignOnAnyway, redirectSomewhere } = this.state;
    const { username, password } = '';
    if (brandings.loading) {
      return (<BlankScreen />);
    }
    if(redirectSomewhere) {
      return (<Navigate to={redirectSomewhere} />);
    }
    console.log(this.showStandardSignOnAnyway)
    return (
      <div>
        <Navbar />
        <Jumbotron
          title={brandingMessage(brandings, 'login_header', 'Login')}
          subtitle={brandingMessage(brandings, 'login_subtitle', 'Without feather ado, please log-in')}
        />
        <div className="container">
          <div className="row">
            {brandings.branding && (brandings.branding.StandardSignOn || showStandardSignOnAnyway) &&
              <div className="col-lg-5 col-md-8 col-sm-12">

                <form name="form" onSubmit={this.handleSubmit} >

                  <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                    <label htmlFor="username">Email</label>
                    <input type="text" className="form-control" name="username" value={username} onChange={(e) => { this.handleChange(e) }} />
                    {submitted && !username &&
                      <div className="help-block">Email is required</div>
                    }
                  </div>
                  <div className={'form-group' + (submitted && !password ? ' has-error' : '')}>
                    <label htmlFor="password">Password</label>
                    <input id="passwordInput" type="password" className="form-control" name="password" value={password} onChange={(e) => { this.handleChange(e) }} autocomplete={(this.props.isReset === 'reset') && "new-password"} />
                    <button id="revealPasswordButton" className="toggle-password ml-2 fa fa-eye" type="button" onClick={() => { this.revealPasswordToggle() }}></button>
                    {submitted && !password &&
                      <div className="help-block">Password is required</div>
                    }
                  </div>
                  <div className="form-group">
                    <button className="btn btn-primary whiteText text-bd body-md" style={{ paddingLeft: "15pt", paddingRight: "15pt", marginRight: '15pt' }}><strong>Login</strong></button>
                    {loggingIn &&
                      <img alt="Logging in..." src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                    }
                    <Link to="/register" className="btn btn-warning blackText text-bd body-md" ><strong>Register</strong></Link><br /><br />
                    <Link to="/reset" className="text-bd body-md" style={{ color: brandings.branding && brandings.branding.TextColour }}><strong>Reset Password</strong></Link><br></br>
                  </div>
                </form>
              </div>
            }
            {(brandings.branding && (brandings.branding.allowMicrosoftSSO || brandings.branding.allowGoogleSSO) && (brandings.branding.StandardSignOn || showStandardSignOnAnyway)) &&
              <div className="col-lg-2 ORCol d-none d-lg-flex">
                <div className="verticalLine"></div>
                <p className="loginOR" style={{ color: brandings.branding && brandings.branding.TextColour }}>OR</p>
                <div className="verticalLine"></div>
              </div>
            }
            {(brandings.branding && (brandings.branding.allowMicrosoftSSO || brandings.branding.allowGoogleSSO) && (brandings.branding.StandardSignOn || showStandardSignOnAnyway)) &&
              <div className="col-md-8 col-sm-12 ORRow d-flex d-lg-none">
                <div className="horizontalLine"></div>
                <p className="loginOR" style={{ color: brandings.branding && brandings.branding.TextColour }}>OR</p>
                <div className="horizontalLine"></div>
              </div>
            }
            <div className="col-lg-5 col-md-8 col-sm-12 flexCol">
              {(brandings.branding && brandings.branding.allowMicrosoftSSO) &&
                <div>
                  <button className="microsoft-btn" type="button" onClick={(e) => { this.singleSignOn('azure') }}><img src="../img/signonwithmicrosoft.png"></img></button>
                </div>
              }
              {(brandings.branding && brandings.branding.allowAuth0SSO) &&
                <div>
                  <button className="auth0-btn" type="button" onClick={(e) => { this.singleSignOn('auth0') }}>{brandings.branding.auth0CustomText}</button>
                </div>
              }
              {(brandings.branding && brandings.branding.allowGoogleSSO) &&
                <div>
                  <button className="google-btn" type="button" onClick={(e) => { this.singleSignOn('google') }}>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" className="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
                    Log on with Google
                  </button>
                </div>
              }
              {(brandings.branding && !brandings.branding.StandardSignOn && !showStandardSignOnAnyway && brandings.branding.showRegisterForm) && <div>
                <button className="btn btn-secondary backToStandardSignOnBtn" onClick={(e) => { this.showStandardSignOn() }}>Go back to standard sign on</button>
              </div>}
            </div>
          </div>
        </div>
      </div>
    );

  }
}

function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { brandings, authentication, licenseagreements } = state;
  return {
    loggingIn, brandings, authentication, licenseagreements
  };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);
export default connectedLoginPage;
