import { authHeader } from '../helpers';
import { generalConstants } from '../constants';

export const contenthistoryService = {
    getAll,
    getById,
    update,
    delete: _delete,
    getAnonymisedHistory
};

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    console.log('getAll of ContentHistory running');

    return fetch(generalConstants.SERVER_URL + "/api/ContentHistories?filter=%7B%22limit%22%3A%201000%2C%20%22order%22%3A%20%22CreatedOn%20DESC%22%7D", requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(generalConstants.SERVER_URL + "/api/ContentHistory/" + id, requestOptions).then(handleResponse);
}

function update(contenthistory) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(contenthistory)
    };

    console.log('Update of ContentHistory running');

    return fetch(generalConstants.SERVER_URL + "/api/ContentHistories", requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    console.log('delete of ContentHistory running');

    return fetch(generalConstants.SERVER_URL + "/api/ContentHistory/" + id, requestOptions).then(handleResponse);;
}

function handleResponse(response) {
    if (!response.ok) {
        return Promise.reject(response.statusText);
    }

    return response.json();
}

function getAnonymisedHistory() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    console.log("Request Options",requestOptions);
    return fetch(generalConstants.SERVER_URL + "/api/vContentHistory_Anonymiseds?filter=%7B%7D", requestOptions).then(handleResponse);

}