import React, { Component } from 'react';
class addButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  onClick(e, i) {
    //send add here
    alert("component added")
  }
  render() {
    if(this.props.enabled){
      return (
        <div className="add" onClick={(e) => {return this.onClick(e, this)}}><i className="fa fa-plus"></i></div>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}
  export default addButton
