import { licenseagreementConstants } from '../constants';

export function licenseagreements(state = {}, action) {
  switch (action.type) {

    case licenseagreementConstants.GETALL_REQUEST:
      return {
        loading: true
      };
    case licenseagreementConstants.GETALL_SUCCESS:
      return {
        items: action.licenseagreements
      };
    case licenseagreementConstants.GETALL_FAILURE:
      return {
        error: action.error
      };

    case licenseagreementConstants.DELETE_REQUEST:
      // add 'deleting:true' property to licenseagreement being deleted
      return {
        ...state,
        items: state.items.map(licenseagreement =>
          licenseagreement.id === action.id
            ? { ...licenseagreement, deleting: true }
            : licenseagreement
        )
      };
    case licenseagreementConstants.DELETE_SUCCESS:
      // remove deleted licenseagreement from state
      return {
        items: state.items.filter(licenseagreement => licenseagreement.id !== action.id)
      };
    case licenseagreementConstants.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to licenseagreement
      return {
        ...state,
        items: state.items.map(licenseagreement => {
          if (licenseagreement.id === action.id) {
            // make copy of licenseagreement without 'deleting:true' property
            const { deleting, ...licenseagreementCopy } = licenseagreement;
            // return copy of licenseagreement with 'deleteError:[error]' property
            return { ...licenseagreementCopy, deleteError: action.error };
          }

          return licenseagreement;
        })
      };

    default:
      return state
  }
}
