import React, { Component } from 'react';
import Video from './Video';

import hexToRgb from '../helpers/hexToRgb.js';

import { connect } from 'react-redux';

class Jumbotron extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentWillReceiveProps(props){
    this.setState({});
  }

  render() {
    const {brandings} = this.props;

    if(!brandings.branding){
      return (<div></div>)
    }
    const rgb = brandings.branding && brandings.branding.BackgroundColour && hexToRgb(brandings.branding.BackgroundColour)
    var opacity = 1;
    if(brandings.branding && brandings.branding.Opacity != null && brandings.branding.Opacity != undefined){
      opacity = brandings.branding.Opacity
    }
    if(this.props.video) {
      return (
        <div className="jumbotron jumbotron-fluid">
          <div className="container">
            <h1 className="inline" style={{color: brandings.branding && brandings.branding.PrimaryColour}}>{this.props.title}</h1>
            <p className="lead inline" style={{color: brandings.branding && brandings.branding.TextColour}}>{this.props.subtitle}</p>
            </div>
        </div>
      )
    }

    return (
      <div className="jumbotron jumbotron-fluid">
        <div className="container">
          <h1 className="" style={{color: brandings.branding && brandings.branding.PrimaryColour}}>{this.props.title}</h1>
          <p className="lead" style={{color: brandings.branding && brandings.branding.TextColour}}>{this.props.subtitle}</p>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
    const { brandings } = state;
    return {
      brandings
    };
}

const connectedJumbotron = connect(mapStateToProps)(Jumbotron);

export default connectedJumbotron;
