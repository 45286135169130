import React, { Component } from 'react';

class DropdownField extends Component {
  constructor(props) {
      super(props);
      this.state = {
        values: props.values,
        curValue: props.curValue,
        displayField: (props.displayField !== undefined) ? props.displayField : "Name",
        linkField: (props.linkField !== undefined) ? props.linkField : "id"
      }
  }

  find(val){
    return this.state.values.filter((v) => v[this.state.linkField] === val)[0]
  }

  componentWillReceiveProps(newProps){
    this.setState(newProps)
  }

  valueChange(e, val){

    this.setState({
      curValue: val
    });
    //Check and set if value has changed
    var changed = this.state.curValue !== val;
    this.setState({changed: changed});

    //If output variable is supplied
    if(this.props.out instanceof Object){
      this.props.out["value"] = this.find(val);
    }

    //If handler function is supplied
    if(typeof(this.props.handler) === "function"){
      this.props.handler({
        code: this.props.code,
        value: this.find(val)
      })
    }
  }

  render (){

      return (

        <div className="dropdown fill">
          <button className="fill btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {this.state.curValue && this.state.values && this.find(this.state.curValue)[this.state.displayField]}
          </button>
          <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <div onClick={(e) => {this.setState({curValue : null})}} className="dropdown-item"> {""} </div>
              {this.state.curValue && this.state.values && this.state.values.map((v) => {return (<div onClick={(e) => {this.valueChange(e, v[this.state.linkField])}} id={v[this.state.linkField]} className="dropdown-item"> {v[this.state.displayField]} </div>)} )}
          </div>
        </div>
      )
  }
}

export default DropdownField
